import { ProspectingStrategyShortList } from 'api/prospectingStrategies/prospectingStrategies.types';
import { JobDetails, Prospecting } from 'api/jobProspecting/jobProspecting.types';

export enum CreateProspectingCurrentStep {
  setup,
  preview,
  sent,
}

export type CreateProspectingProps = {
  currentStep: CreateProspectingCurrentStep;
  prospecting: Prospecting | undefined;
  jobDetails: JobDetails['data'] | undefined;
  onNextStepClick: (data: ProspectingFormBody) => void;
  onPreviousStepClick: () => void;
  isLoadingExecuteProspecting: boolean;
  isLoadingUpdateProspecting: boolean;
  prospectingStrategyShortList: ProspectingStrategyShortList | undefined;
  isSuccessExecuteProspecting: boolean;
  alreadyExistingProspectingId?: string;
  isLoadingProspectingData: boolean;
};

export type ProspectingFormBody = {
  prospectingStrategy: string;
  automaticallyAssignVendor: boolean;
  prospectingId: string;
  expiryAt: string;
  cascades: CascadeFormBody[];
};

export type CascadeFormBody = {
  lifespan: string;
  immortal: boolean;
};

export enum UnitSeconds {
  m = 60,
  h = 3600,
  d = 86400,
  w = 604800,
}
