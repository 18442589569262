import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { message } from 'ui/message/message';

import { guards } from './errorGuards';

export const useError = () => {
  const { formatMessage } = useLocale();

  const errorMessages = {
    NETWORK_ERROR: formatMessage({ id: AppMessages['error.network'] }),
    SERVER_ERROR: formatMessage({ id: AppMessages['error.server'] }),
    UNKNOWN_ERROR: formatMessage({ id: AppMessages['error.unknown'] }),
  };

  const handleError = (e: unknown) => {
    if (e instanceof TypeError) {
      message.error(errorMessages.UNKNOWN_ERROR);

      return;
    }

    if (guards.isNetworkError(e)) {
      message.error(errorMessages.NETWORK_ERROR);

      return;
    }

    if (!guards.isAxiosError(e)) {
      message.error(errorMessages.UNKNOWN_ERROR);

      return;
    }

    if (e.response && e.response.data.message) {
      message.error(e.response.data.message);

      return;
    }

    if (guards.isServerError(e)) {
      message.error(errorMessages.SERVER_ERROR);

      return;
    }

    message.error(errorMessages.UNKNOWN_ERROR);
  };

  return { handleError };
};
