import { Route, Switch } from 'react-router-dom';

import { DemoModeDashboardContainer } from 'app/demoModeDashboard/DemoModeDashboardContainer';
import { JobProspectingStatusPageContainer } from 'app/jobProspectingStatusPage/JobProspectingStatusPageContainer';
import { CreateProspectingContainer } from 'app/createProspecting/CreateProspectingContainer';
import { ProspectingStrategiesListContainer } from 'app/prospectingStrategiesList/ProspectingStrategiesListContainer';
import { ProspectingStrategyCreatorContainer } from 'app/prospectingStrategyCreator/ProspectingStrategyCreatorContainer';
import { JobProspectingDashboardContainer } from 'app/jobProspectingDashboard/JobProspectingDashboardContainer';
import { ErrorPage } from 'ui/errorPage/ErrorPage';

import { AppRoute } from './AppRoute.enum';

export const AppRoutes = () => {
  const createPathWithParams = (route: AppRoute, withId = false) => {
    const id = withId ? '/:id' : '';

    return `/:workspaceId${route}${id}`;
  };

  return (
    <Switch>
      <Route
        exact
        path={createPathWithParams(AppRoute.prospecting, true)}
        component={JobProspectingStatusPageContainer}
      />
      <Route
        exact
        path={createPathWithParams(AppRoute.prospectingWizard, true)}
        component={CreateProspectingContainer}
      />
      <Route
        exact
        path={createPathWithParams(AppRoute.prospectingStrategyCreator, true)}
        component={ProspectingStrategyCreatorContainer}
      />
      <Route
        exact
        path={createPathWithParams(AppRoute.prospectingStrategyCreator)}
        component={ProspectingStrategyCreatorContainer}
      />
      <Route
        exact
        path={createPathWithParams(AppRoute.prospectingStrategiesList)}
        component={ProspectingStrategiesListContainer}
      />
      <Route
        exact
        path={createPathWithParams(AppRoute.jobProspectingDashboard)}
        component={JobProspectingDashboardContainer}
      />

      {process.env.REACT_APP_DEMO_MODE === 'true' && (
        <Route exact path={createPathWithParams(AppRoute.home)} component={DemoModeDashboardContainer} />
      )}

      <Route path={createPathWithParams(AppRoute.notFound)} component={() => <ErrorPage type="notFound" />} />
      <Route component={() => <ErrorPage type="notFound" />} />
    </Switch>
  );
};
