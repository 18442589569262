import { Controller, useFormContext } from 'react-hook-form';
import { Radio, Typography, Space, Input } from 'antd';
import clsx from 'clsx';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { XtrfCard } from 'ui/xtrfCard/XtrfCard';
import { CreatorFormElement } from 'ui/creatorFormElement/CreatorFormElement';

import styles from './GeneralSettings.module.scss';

export const GeneralSettings = () => {
  const { formatMessage } = useLocale();
  const { control, formState } = useFormContext();

  return (
    <XtrfCard
      title={formatMessage({ id: AppMessages['prospectingCreator.generalSettings.title'] })}
      data-testid="generalSettingsCard"
    >
      <Space direction="vertical" className={styles.container}>
        <CreatorFormElement
          title={formatMessage({ id: AppMessages['prospectingCreator.generalSettings.active.title'] })}
          data-testid="activeForm"
          formElement={
            <Controller
              control={control}
              name="active"
              render={({ field }) => (
                <Radio.Group {...field} onChange={(e) => field.onChange(e.target.value)} data-testid="activeInput">
                  <Radio value={true} data-testid="activeYesRadioButton">
                    {formatMessage({ id: AppMessages['prospectingCreator.generalSettings.active.yes'] })}
                  </Radio>
                  <Radio value={false} data-testid="activeNoRadioButton">
                    {formatMessage({ id: AppMessages['prospectingCreator.generalSettings.active.no'] })}
                  </Radio>
                </Radio.Group>
              )}
            />
          }
        />

        <CreatorFormElement
          title={formatMessage({ id: AppMessages['prospectingCreator.generalSettings.description.title'] })}
          errorMessage={formState?.errors['description']?.message}
          data-testid="descriptionForm"
          formElement={
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  data-testid="descriptionInput"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className={clsx(styles.textarea, {
                    [styles.error]: formState?.errors['description']?.message,
                  })}
                />
              )}
            />
          }
        />

        <CreatorFormElement
          title={formatMessage({ id: AppMessages['prospectingCreator.generalSettings.vendorAssigment.title'] })}
          data-testid="vendorAssignmentForm"
          formElement={
            <Controller
              control={control}
              name="automaticallyAssignVendor"
              render={({ field }) => (
                <Radio.Group
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  data-testid="vendorAssignmentInput"
                >
                  <Space direction="vertical">
                    <Radio value={true} data-testid="automaticallyAssignmentRadioButton">
                      {formatMessage({
                        id: AppMessages['prospectingCreator.generalSettings.vendorAssigment.automatically'],
                      })}
                    </Radio>
                    <Radio value={false} data-testid="manuallyAssignmentRadioButton">
                      {formatMessage({
                        id: AppMessages['prospectingCreator.generalSettings.vendorAssigment.manually'],
                      })}
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          }
        />

        <CreatorFormElement
          title={formatMessage({ id: AppMessages['prospectingCreator.generalSettings.expiryTime.title'] })}
          text={formatMessage({ id: AppMessages['prospectingCreator.generalSettings.expiryTime.text'] })}
          errorMessage={formState?.errors['additionalLifespan']?.message}
          data-testid="expiryTimeForm"
          formElement={
            <Controller
              control={control}
              name="additionalLifespan"
              defaultValue=""
              render={({ field }) => (
                <Space>
                  <Input
                    data-testid="expiryTimeInput"
                    {...field}
                    placeholder={formatMessage({
                      id: AppMessages['prospectingCreator.generalSettings.expiryTime.placeholder'],
                    })}
                    className={clsx({
                      [styles.error]: formState?.errors['additionalLifespan']?.message,
                    })}
                  />
                  <Typography.Text>
                    {formatMessage({ id: AppMessages['prospectingCreator.generalSettings.expiryTime.note'] })}
                  </Typography.Text>
                </Space>
              )}
            />
          }
        />
      </Space>
    </XtrfCard>
  );
};
