import { message as antdMessage, MessageArgsProps, Typography } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';

import styles from './message.module.scss';

const baseConfig = {
  icon: <div className={styles.displayNone}></div>,
};

antdMessage.config({
  maxCount: 1,
});

export const message = {
  success: (text: string, options?: Omit<MessageArgsProps, 'type'>) =>
    antdMessage.success({
      key: 'success',
      content: (
        <div className={styles.contentSuccessWrapper}>
          <div className={styles.iconTextWrapper}>
            <div className={styles.iconSuccessWrapper}>
              <CheckOutlined className={styles.icon} />
            </div>
            <Typography.Text className={styles.messageSuccessContent}>{text}</Typography.Text>
          </div>

          <CloseOutlined onClick={() => antdMessage.destroy('success')} className={styles.successCloseIcon} />
        </div>
      ),
      type: 'success',
      duration: 5,
      className: styles.messageWrapper,
      ...baseConfig,
      ...options,
    }),
  error: (text: string, options?: Omit<MessageArgsProps, 'content' | 'duration' | 'type'>) =>
    antdMessage.error({
      key: 'error',
      content: (
        <div className={styles.contentErrorWrapper}>
          <div className={styles.iconTextWrapper}>
            <div className={styles.iconErrorWrapper}>
              <ExclamationCircleOutlined className={styles.icon} />
            </div>
            <Typography.Text className={styles.messageErrorContent}>{text}</Typography.Text>
          </div>

          <CloseOutlined onClick={() => antdMessage.destroy('error')} className={styles.errorCloseIcon} />
        </div>
      ),
      type: 'error',
      duration: 0,
      className: styles.messageWrapper,
      ...baseConfig,
      ...options,
    }),
};
