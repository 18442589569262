import { useState, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  removeProspectingStrategy,
  getProspectingStrategies,
  addProspectingStrategy,
  editProspectingStrategy,
} from 'api/prospectingStrategies/prospectingStrategies';
import { AppRoute } from 'routing/AppRoute.enum';
import { parseTime } from 'helpers/parseTime';
import { useLocale } from 'hooks/useLocale/useLocale';
import { message } from 'ui/message/message';
import { AppMessages } from 'i18n/messages';
import { NegotiationStrategies, QueryKey, RankingStrategies } from 'core/global.enum';
import { RouteParams } from 'core/global.types';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';
import { ProspectingStrategyResponse } from 'api/prospectingStrategies/prospectingStrategies.types';

import { CascadeFormValues, ProspectingFormValues } from './ProspectingStrategyCreator.types';
import { ProspectingStrategyCreator } from './ProspectingStrategyCreator';
import { useProspectingStrategyCreatorValidation } from './ProspectingStrategyCreator.validation';
import { useValidateQueryString } from './cascade/filters/hooks/useValidateQueryString';
import { useValidateSortingQueryString } from './cascade/sorting/hooks/useValidateQueryString';

export const ProspectingStrategyCreatorContainer = () => {
  const { id } = useParams<RouteParams>();
  const history = useCustomHistory();
  const { formatMessage } = useLocale();
  const { schema } = useProspectingStrategyCreatorValidation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCascadeNumber] = useState(0);

  const { data: prospectingStrategiesResponse, isLoading } = useQuery(
    QueryKey.PROSPECTING_STRATEGIES,
    getProspectingStrategies,
  );

  const strategy = prospectingStrategiesResponse?.find((strategy) => strategy.id === id);

  const methods = useForm<ProspectingFormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldFocusError: !!strategy?.cascades.length ? false : true,
    defaultValues: {
      active: id ? strategy?.active : true,
      automaticallyAssignVendor: id ? strategy?.automaticallyAssignVendor : true,
    },
  });
  const { reset, getValues } = methods;

  const { fields, append, remove, insert, move } = useFieldArray({
    control: methods.control,
    name: 'cascades',
    keyName: 'cascadeFormId',
  });

  const { mutateAsync: onAddProspectingStrategy, isLoading: isLoadingAddProspecting } = useMutation(
    QueryKey.PROSPECTING_STRATEGIES,
    addProspectingStrategy,
    {
      onSuccess: (res: ProspectingStrategyResponse) => {
        history.push({
          route: `${AppRoute.prospectingStrategyCreator}/${res.data.id}` as AppRoute,
        });
        message.success(formatMessage({ id: AppMessages['prospectingCreator.savedMessage'] }));
      },
    },
  );

  const { mutateAsync: onEditProspectingStrategy, isLoading: isLoadingEditProspecting } = useMutation(
    QueryKey.PROSPECTING_STRATEGIES,
    editProspectingStrategy,
    {
      onSuccess: () => {
        message.success(formatMessage({ id: AppMessages['prospectingCreator.savedMessage'] }));
        reset(getValues());
      },
    },
  );

  const { mutateAsync: onDeleteProspectingStrategy } = useMutation(
    QueryKey.PROSPECTING_STRATEGIES,
    removeProspectingStrategy,
    {
      onSuccess: () => {
        history.push({
          route: AppRoute.prospectingStrategiesList,
        });
      },
    },
  );

  useEffect(() => {
    if (!strategy) return;

    let cascadeNumber = 0;
    const cascades = strategy.cascades
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map((cascade) => {
        cascadeNumber++;
        return { ...cascade, lifespan: parseTime.toTimeDuration(cascade.lifespan) };
      });
    setCascadeNumber(cascadeNumber);

    reset({
      ...strategy,
      additionalLifespan: parseTime.toTimeDuration(strategy.additionalLifespan),
      cascades,
    });
  }, [strategy, reset]);

  const { validateQueryWithPetty } = useValidateQueryString();
  const validateSortingQueryWithPetty = useValidateSortingQueryString();

  const saveStrategy = async (formValues: ProspectingFormValues) => {
    const updatedFormValues = {
      ...formValues,
      additionalLifespan: parseTime.fromTimeDuration(formValues.additionalLifespan),
      cascades: formValues.cascades.map((cascade, index) => ({
        ...cascade,
        lifespan: parseTime.fromTimeDuration(cascade.lifespan),
        position: index,
        filter: cascade.filter || '',
        isAdvancedFiltering: cascade.isAdvancedFiltering,
      })),
    };

    id
      ? await onEditProspectingStrategy({ id, body: updatedFormValues })
      : await onAddProspectingStrategy(updatedFormValues);
  };

  const onSubmit = (formValues: ProspectingFormValues) => {
    const isInvalidFiltersQueryString = formValues.cascades.some(({ filter: filteringQuery }: CascadeFormValues) => {
      const { isValid } = validateQueryWithPetty(filteringQuery);
      const isPettyError = isValid === false;

      return isPettyError;
    });
    const areFiltersQueriesValid = isInvalidFiltersQueryString === false;

    const isInvalidSortingQueryString = formValues.cascades.some(({ sort: sortingQuery }: CascadeFormValues) => {
      const { isValid } = validateSortingQueryWithPetty(sortingQuery);
      const isPettyError = isValid === false;

      return isPettyError;
    });
    const areSortingQueriesValid = isInvalidSortingQueryString === false;

    if (areFiltersQueriesValid && areSortingQueriesValid) return saveStrategy(formValues);

    if (isInvalidFiltersQueryString)
      return message.error(formatMessage({ id: AppMessages['prospectingCreator.savedMessage.invalidFilters'] }));

    if (isInvalidSortingQueryString)
      return message.error(formatMessage({ id: AppMessages['prospectingCreator.savedMessage.invalidSorting'] }));
  };

  const onEndDrag = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    move(source.index, destination.index);
  };

  const addCascade = () => {
    setCascadeNumber((prevState) => {
      const cascadeNumber = prevState + 1;

      append({
        rankingStrategy: RankingStrategies.firstOneWin,
        negotiationStrategy: NegotiationStrategies.negotiation,
        cascadeNumber,
      });

      return cascadeNumber;
    });
  };

  const duplicateCascade = (index: number, cascade: CascadeFormValues) => {
    const { id, cascadeFormId, isCollapsed, ...rest } = cascade;

    setCascadeNumber((prevState) => {
      const cascadeNumber = prevState + 1;

      insert(index + 1, { ...rest, title: `Cascade ${cascadeNumber}`, cascadeNumber });
      return cascadeNumber;
    });
  };

  const removeCascade = (index: number) => {
    remove(index);
  };

  const deleteProspectingStrategy = () => {
    if (!strategy) return;

    onDeleteProspectingStrategy(strategy.id);
  };

  return (
    <FormProvider {...methods}>
      <ProspectingStrategyCreator
        isLoading={isLoading}
        fields={fields}
        onSubmit={onSubmit}
        addCascade={addCascade}
        duplicateCascade={duplicateCascade}
        removeCascade={removeCascade}
        onEndDrag={onEndDrag}
        isLoadingAddProspecting={isLoadingAddProspecting}
        isLoadingEditProspecting={isLoadingEditProspecting}
        deleteProspectingStrategy={deleteProspectingStrategy}
        prospectingId={id}
      />
    </FormProvider>
  );
};
