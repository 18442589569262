import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AppMessages } from 'i18n/messages';
import { Sort } from '../simpleSorting/SimpleSorting.types';
import { useLocale } from 'hooks/useLocale/useLocale';

import { useQueryString } from './useQueryString';
import { useValidateSortingQueryString } from './useValidateQueryString';

export const useToggleSorting = (cascadeIndex: number) => {
  const [isQueryStringError, setIsQueryStringError] = useState(false);
  const [errorModalMessages, setErrorModalMessages] = useState({ title: '', text: '' });

  const { formatMessage } = useLocale();
  const { setValue, watch } = useFormContext();
  const convertSortingToQueryString = useQueryString();
  const validateQueryWithPetty = useValidateSortingQueryString();

  const isAdvancedSortingOpen = watch(`cascades[${cascadeIndex}].isAdvancedSorting`);
  const sortingQuery = watch(`cascades[${cascadeIndex}].sort`) || '';

  const openAdvancedSorting = () => setValue(`cascades[${cascadeIndex}].isAdvancedSorting`, true);
  const backToSimpleSorting = () => setValue(`cascades[${cascadeIndex}].isAdvancedSorting`, false);

  const toggleSorting = (sorting: Sort[], setSorting: React.Dispatch<React.SetStateAction<Sort[]>>) => {
    if (!!isAdvancedSortingOpen === false) {
      const queryString = convertSortingToQueryString(sorting);
      setValue(`cascades[${cascadeIndex}].sort`, queryString);

      openAdvancedSorting();
      return;
    }

    const { isValid: isPettyValid, flattenedParseOutput, errorMessage } = validateQueryWithPetty(sortingQuery);
    if (isPettyValid === false) {
      setIsQueryStringError(true);
      setErrorModalMessages({
        title: formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.modal.title.petty'] }),
        text: errorMessage,
      });

      return;
    }

    setSorting(flattenedParseOutput);
    backToSimpleSorting();
  };

  return { toggleSorting, isQueryStringError, setIsQueryStringError, errorModalMessages, backToSimpleSorting };
};
