import { useState } from 'react';

import { QueryKey } from 'core/global.enum';
import { cancelExistingJobProspecting } from 'api/jobProspecting/jobProspecting';
import { useMutation } from 'hooks/useMutation/useMutation';

import { ProspectingAlreadyExistsCard } from './ProspectingAlreadyExistsCard';
import { ProspectingAlreadyExistsCardContainerProps } from './ProspectingAlreadyExistsCard.types';

export const ProspectingAlreadyExistsCardContainer = ({
  showPreviousJobProspecting,
  startNewProspecting,
  newProspectingId,
}: ProspectingAlreadyExistsCardContainerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isLoading } = useMutation(QueryKey.CANCEL_EXISTING_JOB_PROSPECTING, cancelExistingJobProspecting, {
    onSuccess: startNewProspecting,
  });

  return (
    <ProspectingAlreadyExistsCard
      showPreviousJobProspecting={showPreviousJobProspecting}
      newProspectingId={newProspectingId}
      onCancelExistingJobProspecting={mutate}
      isLoadingCancelExistingJobProspecting={isLoading}
      isModalOpen={isModalOpen}
      openModal={() => setIsModalOpen(true)}
      closeModal={() => setIsModalOpen(false)}
    />
  );
};
