import { StepProps, Steps } from 'antd';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { StepsBarProps } from './StepsBar.types';
import styles from './StepsBar.module.scss';

export const StepsBar = ({ currentStep }: StepsBarProps) => {
  const { formatMessage } = useLocale();

  return (
    <div className={styles.steps}>
      <Steps current={currentStep} progressDot={(dot: StepProps) => <span className={styles.dot}>{dot}</span>}>
        <Steps.Step
          title={formatMessage({ id: AppMessages['createProspecting.steps.setUpProspecting'] })}
          data-testid="progressSetUpJobProspecting"
        />
        <Steps.Step
          title={formatMessage({ id: AppMessages['createProspecting.steps.previewVendorsList'] })}
          data-testid="progressPreviewVendorsList"
        />
        <Steps.Step
          title={formatMessage({ id: AppMessages['createProspecting.steps.sendJobOffers'] })}
          data-testid="progressSendJobOffers"
        />
      </Steps>
    </div>
  );
};
