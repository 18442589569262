import { ReactNode } from 'react';
import { Card, Divider, Typography } from 'antd';
import classNames from 'clsx';

import { XtrfCardProps } from './XtrfCard.types';
import styles from './XtrfCard.module.scss';

export const XtrfCard = ({
  title,
  extra,
  children,
  loading = false,
  className,
  customTitle,
  'data-testid': dataTestId,
}: XtrfCardProps) => {
  const renderProperTitle = (): ReactNode => {
    if (customTitle) {
      return customTitle;
    } else if (loading || !title) {
      return null;
    } else {
      return <Typography.Title level={4}>{title}</Typography.Title>;
    }
  };

  return (
    <Card
      loading={loading}
      className={classNames(className, styles.card)}
      title={renderProperTitle()}
      extra={loading ? null : extra}
      data-testid={dataTestId}
    >
      {title && !customTitle && <Divider className={styles.divider} />}
      <div className={classNames(styles.body, !title && styles.firstElement)}>{children}</div>
    </Card>
  );
};
