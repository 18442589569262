import { useEffect } from 'react';

import { AppRoutes } from 'routing/AppRoutes';
import { Loader } from 'ui/loader/Loader';
import { useAuth } from 'hooks/useAuth/useAuth';
import { AppRoute } from 'routing/AppRoute.enum';
import { changeAppTitle } from 'helpers/changeAppTitle';

export const App = () => {
  const { isAuthenticating } = useAuth();

  useEffect(() => {
    changeAppTitle([
      {
        onRoute: AppRoute.prospectingStrategiesList,
        title: 'Prospecting Strategies',
      },
      {
        onRoute: AppRoute.prospectingStrategyCreator,
        title: 'Prospecting Strategy',
      },
      {
        onRoute: AppRoute.jobProspectingDashboard,
        title: 'Prospecting Dashboard',
      },
    ]);
  }, []);

  if (isAuthenticating) {
    return <Loader />;
  }

  return <AppRoutes />;
};
