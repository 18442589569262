import { Image } from 'antd';

import loader from '../../assets/images/loader.gif';

import styles from './Loader.module.scss';

export const Loader = () => {
  return (
    <div className={styles.loadingContainer} data-testid="loader">
      <Image src={loader} className={styles.indicator} />
    </div>
  );
};
