/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Space } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getTime } from 'date-fns';
import { UndoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth/useAuth';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Header } from 'ui/header/Header';
import { Loader } from 'ui/loader/Loader';
import { JobDetailsCard } from 'ui/jobDetailsCard/JobDetailsCard';
import { ProspectingStatus, VendorOfferStatus } from 'core/global.enum';
import { AppRoute } from 'routing/AppRoute.enum';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';

import { SendingJobOffersStatusInfo } from './sendingJobOffersStatusInfo/SendingJobOffersStatusInfo';
import { ProspectingSettingsCard } from './prospectingSettingsCard/ProspectingSettingsCard';
import { SendingJobOffersLoader } from './sendingJobOffersLoader/SendingJobOffersLoader';
import { StepsBar } from './stepsBar/StepsBar';
import { useCreateProspectingValidation } from './CreateProspecting.validation';
import { CreateProspectingProps, CreateProspectingCurrentStep, ProspectingFormBody } from './CreateProspecting.types';
import styles from './CreateProspecting.module.scss';
import { ProspectingAlreadyExistsCardContainer } from './prospectingAlreadyExistsCard/ProspectingAlreadyExistsCardContainer';
import { convertDelayTimeToMiliseconds } from './CreateProspecting.utils';
import { PreviewStep } from './steps/previewStep/PreviewStep';

export const CreateProspecting = ({
  currentStep,
  prospecting,
  jobDetails,
  onNextStepClick,
  onPreviousStepClick,
  isLoadingExecuteProspecting,
  isLoadingUpdateProspecting,
  prospectingStrategyShortList,
  isSuccessExecuteProspecting,
  alreadyExistingProspectingId,
  isLoadingProspectingData,
}: CreateProspectingProps) => {
  const history = useHistory();
  const { workspaceId } = useAuth();
  const { formatMessage } = useLocale();

  const { schema } = useCreateProspectingValidation();
  const [isNewProspectingConfirmed, setIsNewProspectingConfirmed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenOneCascade, setIsModalOpenOneCascade] = useState(false);
  const [isInputDateError, setIsInputDateError] = useState(false);
  const [isInputTimeError, setIsInputTimeError] = useState(false);

  const methods = useForm<ProspectingFormBody>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const isAnyCascadeWithVendorOffer =
    prospecting?.cascades.find((cascade) => cascade.vendorOffers.length) !== undefined;
  const areCascadesAndOffersValid =
    prospecting?.cascades && prospecting?.cascades.length && isAnyCascadeWithVendorOffer;
  const isSendingJobOffers = isSuccessExecuteProspecting && prospecting?.status === ProspectingStatus.prepared;
  const areJobOffersSent = prospecting?.cascades
    .flatMap((cascade) => cascade.vendorOffers)
    .find((vo) => vo.status === VendorOfferStatus.sent);
  const isSetupStep = currentStep === CreateProspectingCurrentStep.setup;
  const isPreviewStep = currentStep === CreateProspectingCurrentStep.preview;
  const isSentStep = currentStep === CreateProspectingCurrentStep.sent;
  const isProspectingExistsStep = alreadyExistingProspectingId && isNewProspectingConfirmed === false;
  const cascadesFormValues = methods.watch('cascades');

  const [expiryTimeBasedOnCascadesDelay, setExpiryTimeBasedOnCascadesDelay] = useState(new Date());

  useEffect(() => {
    if (cascadesFormValues !== undefined) {
      const currentDateTimestamp = getTime(new Date());
      const cascadesLifespan = cascadesFormValues.map((cascade) => cascade.lifespan);
      const areLifespansInvalid = cascadesLifespan.some((lifespan) => convertDelayTimeToMiliseconds(lifespan) === null);

      if (areLifespansInvalid) return;

      const cascadesLifespanTimestamp = cascadesLifespan.reduce(
        (acc, curr) => acc + convertDelayTimeToMiliseconds(curr)!,
        0,
      );
      const lastCascadeLifespanTimestamp = prospecting?.additionalLifespan || 0;
      const expiryTimestamp = currentDateTimestamp + cascadesLifespanTimestamp + lastCascadeLifespanTimestamp;

      methods.setValue('expiryAt', (new Date(expiryTimestamp) as unknown) as string);
      setExpiryTimeBasedOnCascadesDelay(new Date(expiryTimestamp));
    }
  }, [JSON.stringify(cascadesFormValues)]);

  const showPreviousJobProspecting = () =>
    history.push(`/${workspaceId}${AppRoute.prospecting}/${alreadyExistingProspectingId ?? ''}`);

  const sentAfterTimes = cascadesFormValues
    ?.map((cascade) => convertDelayTimeToMiliseconds(cascade.lifespan))
    .reduce((prev: number[], curr) => {
      if (curr === null) return prev;

      const additionalTime = prev.length > 0 ? prev[prev.length - 1] : 0;
      const sentAfterTime = curr + additionalTime;

      prev.push(sentAfterTime);
      return prev;
    }, []);

  const validateExpiryTimeAndSendJobOffers = (data: ProspectingFormBody) => {
    const expiryTimestamp = getTime(new Date(data.expiryAt));
    const currentDateTimestamp = getTime(new Date());
    const cascadesLifespanTimestamp =
      cascadesFormValues !== undefined
        ? cascadesFormValues
            .map((cascade) => cascade.lifespan)
            .reduce((acc, curr) => acc + convertDelayTimeToMiliseconds(curr)!, 0)
        : 0;

    const isExpiryTimeBeforeCurrentDate = expiryTimestamp < currentDateTimestamp;
    const isExpiryTimeTooShort = expiryTimestamp < currentDateTimestamp + cascadesLifespanTimestamp;

    if (isExpiryTimeBeforeCurrentDate) {
      setIsModalOpenOneCascade(true);
      return;
    }

    if (isExpiryTimeTooShort) {
      setIsModalOpen(true);
      return;
    }

    onNextStepClick(data);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Header
        title={formatMessage({ id: AppMessages['createProspecting.header.title'] }, { jobNumber: jobDetails?.number })}
        data-testid="createProspectingTitle"
      >
        {isProspectingExistsStep && (
          <Button
            data-testid="showPreviousProspectingButton"
            onClick={showPreviousJobProspecting}
            icon={<UndoOutlined className={styles.prevIcon} />}
          >
            <FormattedMessage id="createProspecting.buttons.showPreviousJobProspecting" />
          </Button>
        )}
      </Header>

      {isProspectingExistsStep ? (
        <ProspectingAlreadyExistsCardContainer
          showPreviousJobProspecting={showPreviousJobProspecting}
          startNewProspecting={() => setIsNewProspectingConfirmed(true)}
          newProspectingId={prospecting?.id ?? ''}
        />
      ) : (
        <>
          <div className={styles.pageContent} data-testid="createProspectingPageContent">
            <StepsBar currentStep={currentStep} />

            {isSentStep && (isSendingJobOffers === true || areJobOffersSent === undefined) ? (
              <SendingJobOffersLoader />
            ) : isLoadingExecuteProspecting || isLoadingUpdateProspecting ? (
              <Loader />
            ) : (
              <div className={styles.prospectingPlanDetails} data-testid="createProspectingPlanDetails">
                {!isSentStep && <JobDetailsCard jobDetails={jobDetails} />}

                <FormProvider {...methods}>
                  <Form layout="vertical" className={styles.formContainer} data-testid="createProspectingForm">
                    {isSetupStep && !isLoadingProspectingData && (
                      <ProspectingSettingsCard
                        prospecting={prospecting}
                        prospectingStrategyShortList={prospectingStrategyShortList}
                      />
                    )}

                    {isPreviewStep && (
                      <PreviewStep
                        prospecting={prospecting}
                        sentAfterTimes={sentAfterTimes}
                        isLoadingProspectingData={isLoadingProspectingData}
                        areCascadesAndOffersValid={!!areCascadesAndOffersValid}
                        jobDetails={jobDetails}
                        isInputDateError={isInputDateError}
                        setIsInputDateError={setIsInputDateError}
                        isInputTimeError={isInputTimeError}
                        setIsInputTimeError={setIsInputTimeError}
                        expiryTimeBasedOnCascadesDelay={expiryTimeBasedOnCascadesDelay}
                      />
                    )}

                    {isSentStep && <SendingJobOffersStatusInfo />}
                  </Form>
                </FormProvider>
              </div>
            )}
          </div>

          {!isSentStep && (
            <div className={styles.formActionsBar} data-testid="formActionBar">
              <Space>
                {isPreviewStep && (
                  <>
                    <Button
                      data-testid="backButton"
                      onClick={() => {
                        onPreviousStepClick();
                        methods.reset();
                        window.scrollTo(0, 0);
                      }}
                      disabled={isLoadingExecuteProspecting}
                    >
                      <FormattedMessage id="createProspecting.buttons.back" />
                    </Button>

                    <Button
                      data-testid="sendJobOffersButton"
                      type="primary"
                      onClick={methods.handleSubmit(validateExpiryTimeAndSendJobOffers)}
                      disabled={
                        !areCascadesAndOffersValid ||
                        isLoadingExecuteProspecting ||
                        isInputDateError ||
                        isInputTimeError
                      }
                    >
                      <FormattedMessage id="createProspecting.buttons.sendJobOffers" />
                    </Button>
                  </>
                )}

                {isSetupStep && (
                  <Button
                    data-testid="continueButton"
                    type="primary"
                    onClick={methods.handleSubmit((data) => {
                      onNextStepClick(data);
                      methods.reset();
                      window.scrollTo(0, 0);
                    })}
                    disabled={isLoadingUpdateProspecting || !prospecting}
                  >
                    <FormattedMessage id="createProspecting.buttons.continue" />
                  </Button>
                )}
              </Space>
            </div>
          )}
        </>
      )}

      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={methods.handleSubmit((data) => {
          setIsModalOpen(false);
          onNextStepClick(data);
          window.scrollTo(0, 0);
        })}
        text={<FormattedMessage id="createProspecting.validation.expiryTimeTooShort" />}
        submitButtonText={<FormattedMessage id="createProspecting.buttons.sendJobOffers" />}
        cancelButtonText={<FormattedMessage id="createProspecting.buttons.back" />}
      />

      <ConfirmModal
        isOpen={isModalOpenOneCascade}
        onCancel={() => setIsModalOpenOneCascade(false)}
        text={<FormattedMessage id="createProspecting.validation.expiryTimeTooShortOneCascade" />}
        cancelButtonText={<FormattedMessage id="createProspecting.buttons.back" />}
      />
    </>
  );
};
