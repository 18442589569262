import { UseQueryFn } from 'hooks/useQuery/useQuery.types';
import { MutationFn } from 'hooks/useMutation/useMutation.types';

import {
  ProspectingDataResponse,
  PrepareProspectingPayload,
  PrepareProspectingResponse,
  ExecuteProspectingPayload,
  AssignVendorToJobPayload,
  AssignVendorToJobResponse,
  ReassignVendorToJobPayload,
  ReassignVendorToJobResponse,
  ExecuteProspectingResponse,
  CreateTestProspectingPayload,
  CreateTestProspectingResponse,
  ProspectingListPayload,
  ProspectingListResponse,
  CancelExistingJobProspectingPayload,
} from './jobProspecting.types';

export const getProspectingData: UseQueryFn<string, ProspectingDataResponse> = (id) => {
  return {
    endpoint: `/prospecting/${id}`,
  };
};

export const prepareProspecting: MutationFn<PrepareProspectingPayload, PrepareProspectingResponse> = (body) => {
  return {
    endpoint: `/prospecting/${body.id}/prepare`,
    method: 'PATCH',
    params: {
      ...body,
    },
  };
};

export const executeProspecting: MutationFn<ExecuteProspectingPayload, ExecuteProspectingResponse> = ({
  prospectingId,
  ...body
}) => {
  return {
    endpoint: `/prospecting/${prospectingId}/execute`,
    method: 'POST',
    params: {
      ...body,
    },
  };
};

export const assignVendorToJob: MutationFn<AssignVendorToJobPayload, AssignVendorToJobResponse> = ({
  prospectingId,
  ...body
}) => {
  return {
    endpoint: `/prospecting/${prospectingId}/assignVendor`,
    method: 'POST',
    params: {
      ...body,
    },
  };
};

export const reassignVendorToJob: MutationFn<ReassignVendorToJobPayload, ReassignVendorToJobResponse> = ({
  prospectingId,
  ...body
}) => {
  return {
    endpoint: `/prospecting/${prospectingId}/reassignVendor`,
    method: 'POST',
    params: {
      ...body,
    },
  };
};

export const getProspectingList: UseQueryFn<ProspectingListPayload, ProspectingListResponse> = () => {
  return {
    endpoint: `/prospecting/list`,
  };
};

export const cancelProspecting: MutationFn<string, string> = (id) => {
  return {
    endpoint: `/prospecting/${id}`,
    method: 'PUT',
  };
};

export const createTestJob: MutationFn<void, string> = () => {
  return {
    endpoint: `/demo-mode/create-test-job`,
    method: 'POST',
  };
};

export const createTestProspecting: MutationFn<CreateTestProspectingPayload, CreateTestProspectingResponse> = (
  body,
) => {
  return {
    endpoint: `/demo-mode/create-test-prospecting`,
    method: 'POST',
    params: {
      ...body,
    },
  };
};

export const cancelExistingJobProspecting: MutationFn<CancelExistingJobProspectingPayload, ProspectingDataResponse> = (
  body,
) => {
  return {
    endpoint: `/prospecting/cancelAndGet`,
    method: 'POST',
    params: {
      ...body,
    },
  };
};
