import { ast } from 'peggy';

import { FilterFunctions, FilterTypes, FilterValues, FilterValueTypes } from './simpleFilters/SimpleFilters.types';

export type FlattenExpression = {
  operator: string;
  property: string;
  value: FilterValues;
};

export enum QueryStringProperties {
  INDIVIDUAL = 'individual',
  IN_HOUSE = 'inHouse',
  PREFERRED_BY_CLIENT = 'preferredByClient',
  NATIVE_LANGUAGE = 'nativeLanguage',
  COUNTRY = 'country',
  EVALUATION = 'evaluation',
  TOOLS = 'tools',
  LAST_JOB_DATE = 'lastJobDate',
  LAST_JOB_DATE_FOR_THIS_CLIENT = 'lastJobDateForThisClient',
  WORKED_FOR_THIS_CLIENT = 'workedForThisClient',
  CATEGORIES = 'categories',
}

export enum ParseProperties {
  INDIVIDUAL = 'INDIVIDUAL',
  IN_HOUSE = 'IN_HOUSE',
  PREFERRED_BY_CLIENT = 'PREFERRED_BY_CLIENT',
  NATIVE_LANGUAGE = 'NATIVE_LANGUAGE',
  COUNTRY = 'COUNTRY',
  EVALUATION = 'EVALUATION',
  TOOLS = 'TOOLS',
  LAST_JOB_DATE = 'LAST_JOB_DATE',
  LAST_JOB_DATE_FOR_THIS_CLIENT = 'LAST_JOB_DATE_FOR_THIS_CLIENT',
  WORKED_FOR_THIS_CLIENT = 'WORKED_FOR_THIS_CLIENT',
  CATEGORIES = 'CATEGORIES',
}

export type ParseExpression = {
  operator: FilterFunctions;
  property?: ParseProperties;
  value?: FilterValues | string[];
  left?: ParseExpression;
  right?: ParseExpression;
};

export type ParseOutput = ast.Grammar & ParseExpression;

export type FiltersProps = {
  cascadeIndex: number;
};

export enum QueryStringOriginalValuesTypes {
  individual = 'boolean',
  inHouse = 'boolean',
  preferredByClient = 'boolean',
  nativeLanguage = 'boolean',
  country = 'string',
  evaluation = 'number',
  tools = 'object',
  lastJobDate = 'string',
  lastJobDateForThisClient = 'string',
  workedForThisClient = 'boolean',
  categories = 'object',
}

export type FlattenedFilter = {
  id: string;
  type: FilterTypes;
  function: FilterFunctions;
  originalValue?: string | number | boolean | Array<string>;
  value: FilterValues;
  valueType: FilterValueTypes;
};
