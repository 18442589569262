import { CascadeSettingsCard } from 'app/createProspecting/cascadeSettingsCard/CascadeSettingsCard';
import { ProspectingExpiryTimeCardContainer } from 'app/createProspecting/prospectingExpiryTimeCard/ProspectingExpiryTimeCardContainer';
import { Loader } from 'ui/loader/Loader';

import { PreviewStepProps } from './PreviewStep.types';

export const PreviewStep = ({
  prospecting,
  sentAfterTimes,
  isLoadingProspectingData,
  areCascadesAndOffersValid,
  jobDetails,
  isInputDateError,
  setIsInputDateError,
  isInputTimeError,
  setIsInputTimeError,
  expiryTimeBasedOnCascadesDelay,
}: PreviewStepProps) => {
  if (isLoadingProspectingData) return <Loader />;

  return (
    <>
      {prospecting?.cascades
        .sort((a, b) => a.position - b.position)
        .map((cascade, index) => (
          <CascadeSettingsCard
            cascade={cascade}
            index={index}
            key={index}
            lastCascade={index === prospecting.cascades.length - 1}
            sentAfter={
              sentAfterTimes !== undefined && sentAfterTimes.length > 0 ? sentAfterTimes[index - 1] : undefined
            }
          />
        ))}

      {prospecting && areCascadesAndOffersValid && (
        <ProspectingExpiryTimeCardContainer
          jobDetails={jobDetails}
          prospecting={prospecting}
          isInputDateError={isInputDateError}
          setIsInputDateError={setIsInputDateError}
          isInputTimeError={isInputTimeError}
          setIsInputTimeError={setIsInputTimeError}
          expiryTimeBasedOnCascadesDelay={expiryTimeBasedOnCascadesDelay}
        />
      )}
    </>
  );
};
