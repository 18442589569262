export enum AppRoute {
  home = '/',
  prospecting = '/prospecting',
  prospectingWizard = '/prospecting-wizard',
  prospectingStrategyCreator = '/prospecting-strategy-creator',
  prospectingStrategiesList = '/prospecting-strategies',
  jobProspectingDashboard = '/prospecting-dashboard',
  notFound = '/404',
  noAccess = '/403',
}
