import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  ClientNameSort,
  FinishedAtSort,
  JobNumberSort,
  ManagerNamesSort,
  ProjectNameSort,
  ProspectingListElement,
  StartedAtSort,
  StatusSort,
} from 'api/jobProspecting/jobProspecting.types';
import { XtrfCard } from 'ui/xtrfCard/XtrfCard';
import { StatusLabel } from 'ui/statusLabel/StatusLabel';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';
import { NO_VALUE_SYMBOL } from 'core/global.constants';
import { formatDate } from 'helpers/formatDate';

import { Filters } from './filters/Filters';
import { JobOfferProspectingDashboardProps } from './JobProspectingDashboard.types';
import { SortColumnHeader } from './sortColumnHeader/SortColumnHeader';
import styles from './JobProspectingDashboard.module.scss';

export const JobProspectingDashboard = ({
  prospectingListResponse,
  isLoading,
  query,
  setQuery,
  page,
  setPage,
  perPage,
  toggleSortQuery,
}: JobOfferProspectingDashboardProps) => {
  const history = useCustomHistory();
  const { formatMessage } = useLocale();

  const columns: ColumnsType<ProspectingListElement> = [
    {
      title: (
        <SortColumnHeader
          title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.client'] })}
          onClick={() => toggleSortQuery(ClientNameSort.ASC, ClientNameSort.DESC)}
          isSorted={query.sort === ClientNameSort.ASC}
          isSortActive={query.sort === ClientNameSort.ASC || query.sort === ClientNameSort.DESC}
        />
      ),
      dataIndex: 'clientName',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingClientName',
        },
        children: columnData.clientName,
      }),
    },
    {
      title: (
        <SortColumnHeader
          title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.project'] })}
          onClick={() => toggleSortQuery(ProjectNameSort.ASC, ProjectNameSort.DESC)}
          isSorted={query.sort === ProjectNameSort.ASC}
          isSortActive={query.sort === ProjectNameSort.ASC || query.sort === ProjectNameSort.DESC}
        />
      ),
      dataIndex: 'projectName',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingProjectName',
        },
        children: columnData.projectName,
      }),
    },
    {
      title: (
        <SortColumnHeader
          title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.job'] })}
          onClick={() => toggleSortQuery(JobNumberSort.ASC, JobNumberSort.DESC)}
          isSorted={query.sort === JobNumberSort.ASC}
          isSortActive={query.sort === JobNumberSort.ASC || query.sort === JobNumberSort.DESC}
        />
      ),
      dataIndex: 'jobNumber',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingJobNumber',
        },
        children: `${columnData.jobNumber}${
          !columnData.sourceLanguages?.length || !columnData.targetLanguages?.length
            ? ''
            : ` (${columnData.sourceLanguages?.join(', ').toUpperCase()} ▸ ${columnData.targetLanguages
                ?.join(', ')
                .toUpperCase()})`
        }`,
      }),
    },
    {
      title: (
        <SortColumnHeader
          title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.managers'] })}
          onClick={() => toggleSortQuery(ManagerNamesSort.ASC, ManagerNamesSort.DESC)}
          isSorted={query.sort === ManagerNamesSort.ASC}
          isSortActive={query.sort === ManagerNamesSort.ASC || query.sort === ManagerNamesSort.DESC}
        />
      ),
      dataIndex: 'managerNames',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingProjectManagers',
        },
        children: columnData.managerNames || '-',
      }),
    },
    {
      title: (
        <SortColumnHeader
          title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.started'] })}
          onClick={() => toggleSortQuery(StartedAtSort.ASC, StartedAtSort.DESC)}
          isSorted={query.sort === StartedAtSort.ASC}
          isSortActive={query.sort === StartedAtSort.ASC || query.sort === StartedAtSort.DESC}
        />
      ),
      dataIndex: 'startedAt',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingStartedAt',
        },
        children: columnData.startedAt ? formatDate(columnData.startedAt) : NO_VALUE_SYMBOL,
      }),
      width: 200,
    },
    {
      title: (
        <SortColumnHeader
          title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.finished'] })}
          onClick={() => toggleSortQuery(FinishedAtSort.ASC, FinishedAtSort.DESC)}
          isSorted={query.sort === FinishedAtSort.ASC}
          isSortActive={query.sort === FinishedAtSort.ASC || query.sort === FinishedAtSort.DESC}
        />
      ),
      dataIndex: 'finishedAt',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingFinishedAt',
        },
        children: columnData.finishedAt ? formatDate(columnData.finishedAt) : NO_VALUE_SYMBOL,
      }),
      width: 200,
    },
    {
      title: () => (
        <Typography.Text className={styles.statusTableHeader}>
          <SortColumnHeader
            title={formatMessage({ id: AppMessages['jobProspectingDashboard.table.header.status'] })}
            onClick={() => toggleSortQuery(StatusSort.ASC, StatusSort.DESC)}
            isSorted={query.sort === StatusSort.ASC}
            isSortActive={query.sort === StatusSort.ASC || query.sort === StatusSort.DESC}
          />
        </Typography.Text>
      ),
      dataIndex: 'status',
      render: (_, columnData) => ({
        props: {
          'data-testid': 'prospectingStatus',
        },
        children: <StatusLabel status={columnData.status} />,
      }),
      width: 210,
    },
  ];

  return (
    <div className={styles.container}>
      <XtrfCard
        customTitle={
          <Typography.Title level={2}>
            {formatMessage({ id: AppMessages['jobProspectingDashboard.title'] })}
          </Typography.Title>
        }
        extra={<Filters prospectingListResponse={prospectingListResponse} query={query} setQuery={setQuery} />}
        className={styles.card}
        loading={isLoading}
      >
        <Table
          columns={columns}
          rowClassName={styles.tableRow}
          loading={isLoading}
          onRow={(record, index) => ({
            onClick: () =>
              history.push({
                route: AppRoute.prospecting,
                id: record.prospectingId,
              }),
            'data-testid': `prospectingDashboardRowIndex${index}`,
          })}
          rowKey="prospectingId"
          dataSource={prospectingListResponse?.data}
          size="small"
          pagination={{
            total: prospectingListResponse?.length,
            showSizeChanger: false,
            pageSize: perPage,
            current: page,
            onChange: (page) => setPage(page),
            className: styles.pagination,
          }}
        />
      </XtrfCard>
    </div>
  );
};
