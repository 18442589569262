import {
  UseMutationResult,
  useMutation as useRQMutation,
  UseMutationOptions,
  MutationFunction,
  MutationKey,
} from 'react-query';
import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { useClient } from 'hooks/useClient/useClient';
import { useError } from 'hooks/useError/useError';

import { MutationFn } from './useMutation.types';

export const useMutation = <TData = unknown, TError = unknown, TParams = unknown, TContext = unknown>(
  mutationKey: MutationKey,
  mutation: MutationFn<TParams, TData>,
  options?: UseMutationOptions<TData, TError, TParams, TContext>,
): UseMutationResult<TData, TError, TParams, TContext> => {
  const { handleError } = useError();
  const client = useClient();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mutationFn: MutationFunction<TData, TParams> = useCallback(
    (variables) => {
      const { endpoint, params, method } = mutation(variables);

      const axiosConfig: AxiosRequestConfig = {
        url: endpoint,
        data: params ? JSON.stringify(params) : undefined,
        method: method || 'POST',
      };

      return client.request(axiosConfig);
    },
    [client, mutation],
  );

  const errorFn = (error: TError, variables: TParams, context: TContext | undefined) => {
    handleError(error);

    if (!options?.onError) return;

    options?.onError(error, variables, context);
  };

  return useRQMutation<TData, TError, TParams, TContext>(mutationKey, mutationFn, { ...options, onError: errorFn });
};
