export type Filter = {
  id: string;
  type: FilterTypes;
  function: FilterFunctions;
  value: FilterValues;
  valueType: FilterValueTypes;
};

export type SimpleFiltersProps = {
  filters: Filter[];
  onAddFilter: (option: FilterTypes) => void;
  onDeleteFilter: (addedFilterId: Filter['id']) => void;
  onChangeFilterType: (selected: FilterTypes, addedFilterId: Filter['id']) => void;
  onChangeFilterFunction: (selected: FilterFunctions, addedFilterId: Filter['id']) => void;
  onChangeFilterValue: (value: string, addedFilterId: Filter['id']) => void;
};

export type SimpleFiltersContainerProps = {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
};

export type UseFiltersMethodsParams = SimpleFiltersContainerProps;

export enum FilterValueTypes {
  string = 'string',
  number = 'number',
  select = 'select',
}

export enum FilterTypes {
  individual = 'individual',
  inHouse = 'inHouse',
  preferredByClient = 'preferredByClient',
  nativeLanguage = 'nativeLanguage',
  country = 'country',
  evaluation = 'evaluation',
  tools = 'tools',
  lastJobDate = 'lastJobDate',
  lastJobDateForThisClient = 'lastJobDateForThisClient',
  workedForThisClient = 'workedForThisClient',
  categories = 'categories',
}

export enum DateItems {
  '-7d' = '-7d',
  '-14d' = '-14d',
  '-1m' = '-1m',
  '-3m' = '-3m',
  '-6m' = '-6m',
  '-1y' = '-1y',
}

export enum BooleanItems {
  true = 'true',
  false = 'false',
}

export type FilterValues = BooleanItems | DateItems | FilterValueTypes | string;

export enum BooleanFunctions {
  is = 'IS',
  isNot = 'IS_NOT',
}

export enum CountryFunctions {
  exactlyMatches = 'EXACTLY_MATCHES',
  contains = 'CONTAINS',
  startsWith = 'STARTS_WITH',
  endsWith = 'ENDS_WITH',
  doesNotContain = 'DOES_NOT_CONTAIN',
  doesNotStartWith = 'DOES_NOT_START_WITH',
  doesNotEndWith = 'DOES_NOT_END_WITH',
}

export enum EvaluationFunctions {
  isHigher = 'IS_HIGHER',
  isHigherOrEqual = 'IS_HIGHER_OR_EQUAL',
  is = 'IS',
  isLowerThan = 'IS_LOWER',
  isLowerThanOrEqual = 'IS_LOWER_OR_EQUAL',
}

export enum ToolsFunctions {
  includesAllOf = 'INCLUDES_ALL_OF',
  includesAnyOf = 'INCLUDES_ANY_OF',
  includesNoneOf = 'INCLUDES_NONE_OF',
}

export enum DateFunctions {
  isEarlierThan = 'IS_EARLIER_THAN',
  isLaterThan = 'IS_LATER_THAN',
}

export enum CategoriesFunctions {
  includesAllOf = 'INCLUDES_ALL_OF',
  includesAnyOf = 'INCLUDES_ANY_OF',
  includesNoneOf = 'INCLUDES_NONE_OF',
}

export type FilterFunctions =
  | BooleanFunctions
  | CountryFunctions
  | EvaluationFunctions
  | ToolsFunctions
  | DateFunctions
  | CategoriesFunctions;

export enum FilteringOptionsMessages {
  individual = 'prospectingCreator.simpleFilters.individual',
  inHouse = 'prospectingCreator.simpleFilters.inHouse',
  preferredByClient = 'prospectingCreator.simpleFilters.preferredByClient',
  nativeLanguage = 'prospectingCreator.simpleFilters.nativeLanguage',
  country = 'prospectingCreator.simpleFilters.country',
  evaluation = 'prospectingCreator.simpleFilters.evaluation',
  tools = 'prospectingCreator.simpleFilters.tools',
  lastJobDate = 'prospectingCreator.simpleFilters.lastJobDate',
  lastJobDateForThisClient = 'prospectingCreator.simpleFilters.lastJobDateForThisClient',
  workedForThisClient = 'prospectingCreator.simpleFilters.workedForThisClient',
  categories = 'prospectingCreator.simpleFilters.categories',
}

export enum FilteringFunctionsMessages {
  IS = 'prospectingCreator.simpleFilters.is',
  IS_NOT = 'prospectingCreator.simpleFilters.isNot',
  INCLUDES_ALL_OF = 'prospectingCreator.simpleFilters.includesAllOf',
  INCLUDES_ANY_OF = 'prospectingCreator.simpleFilters.includesAnyOf',
  INCLUDES_NONE_OF = 'prospectingCreator.simpleFilters.includesNoneOf',
  EXACTLY_MATCHES = 'prospectingCreator.simpleFilters.exactlyMatches',
  CONTAINS = 'prospectingCreator.simpleFilters.contains',
  STARTS_WITH = 'prospectingCreator.simpleFilters.startsWith',
  ENDS_WITH = 'prospectingCreator.simpleFilters.endsWith',
  DOES_NOT_CONTAIN = 'prospectingCreator.simpleFilters.doesNotContain',
  DOES_NOT_START_WITH = 'prospectingCreator.simpleFilters.doesNotStartWith',
  DOES_NOT_END_WITH = 'prospectingCreator.simpleFilters.doesNotEndWith',
  IS_EARLIER_THAN = 'prospectingCreator.simpleFilters.isEarlierThan',
  IS_LATER_THAN = 'prospectingCreator.simpleFilters.isLaterThan',
  IS_HIGHER = 'prospectingCreator.simpleFilters.isHigher',
  IS_HIGHER_OR_EQUAL = 'prospectingCreator.simpleFilters.isHigherOrEqual',
  IS_LOWER = 'prospectingCreator.simpleFilters.isLower',
  IS_LOWER_OR_EQUAL = 'prospectingCreator.simpleFilters.isLowerOrEqual',
}

export enum FilteringItemsMessages {
  true = 'prospectingCreator.simpleFilters.yes',
  false = 'prospectingCreator.simpleFilters.no',
  '-7d' = 'prospectingCreator.simpleFilters.oneWeek',
  '-14d' = 'prospectingCreator.simpleFilters.twoWeeks',
  '-1m' = 'prospectingCreator.simpleFilters.oneMonth',
  '-3m' = 'prospectingCreator.simpleFilters.threeMonths',
  '-6m' = 'prospectingCreator.simpleFilters.sixMonths',
  '-1y' = 'prospectingCreator.simpleFilters.oneYear',
}
