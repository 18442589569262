import { Button } from 'antd';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

import { EnvelopeIcon } from 'assets/icons/EnvelopeIcon';
import { AppRoute } from 'routing/AppRoute.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { RouteParams } from 'core/global.types';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';

import styles from './SendingJobOffersStatusInfo.module.scss';

export const SendingJobOffersStatusInfo = () => {
  const { formatMessage } = useLocale();
  const { id } = useParams<RouteParams>();
  const history = useCustomHistory();

  return (
    <div className={styles.container} data-testid="sendingJobOffersStatusInfo">
      <div className={styles.iconsWrapper}>
        <EnvelopeIcon width={96} height={96} />

        <CheckCircleFilled className={styles.checkIcon} />
      </div>

      <div className={styles.sendingOffersInfo}>
        {formatMessage({ id: AppMessages['createProspecting.statusInfo'] })}
      </div>
      <Button
        className={clsx(styles.navigationButton)}
        onClick={() =>
          history.push({
            route: AppRoute.prospecting,
            id,
          })
        }
      >
        {formatMessage({ id: AppMessages['createProspecting.showStatusButton'] })}
      </Button>
    </div>
  );
};
