import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatISO } from 'date-fns';

import { executeProspecting, getProspectingData, prepareProspecting } from 'api/jobProspecting/jobProspecting';
import { getProspectingStrategyShortList } from 'api/prospectingStrategies/prospectingStrategies';
import { QueryKey } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { useMutation } from 'hooks/useMutation/useMutation';
import { changeAppTitle } from 'helpers/changeAppTitle';
import { AppRoute } from 'routing/AppRoute.enum';

import { CreateProspectingCurrentStep, ProspectingFormBody } from './CreateProspecting.types';
import { CreateProspecting } from './CreateProspecting';
import { convertDelayTimeToMiliseconds } from './CreateProspecting.utils';

export const CreateProspectingContainer = () => {
  const { id } = useParams<{ id: string }>();

  const [currentStep, setCurrentStep] = useState(CreateProspectingCurrentStep.setup);

  const { mutateAsync: onUpdateProspecting, isLoading: isLoadingUpdateProspecting } = useMutation(
    QueryKey.PREPARE_PROSPECTING,
    prepareProspecting,
    {
      onSuccess: () => {
        refetch();
        setCurrentStep(CreateProspectingCurrentStep.preview);
      },
    },
  );

  const {
    mutateAsync: onExecuteProspecting,
    isLoading: isLoadingExecuteProspecting,
    isSuccess: isSuccessExecuteProspecting,
  } = useMutation(QueryKey.EXECUTE_PROSPECTING, executeProspecting, {
    onSuccess: () => {
      setCurrentStep(CreateProspectingCurrentStep.sent);
    },
  });

  const { data: prospectingDataResponse, refetch, isLoading, isFetching } = useQuery(
    [QueryKey.PROSPECTING_DATA, id],
    () => getProspectingData(id),
    { refetchInterval: isSuccessExecuteProspecting ? 2000 : undefined },
  );

  const { data: prospectingStrategyShortList } = useQuery([QueryKey.PROSPECTING_STRATEGY_SHORT_LIST, id], () =>
    getProspectingStrategyShortList(),
  );

  const handleNextStepClick = async (formValues: ProspectingFormBody) => {
    if (!prospectingDataResponse?.data) return;

    switch (currentStep) {
      case CreateProspectingCurrentStep.setup:
        await onUpdateProspecting({
          id: prospectingDataResponse.data.prospecting.id,
          prospectingStrategyId: formValues.prospectingStrategy,
          automaticallyAssignVendor: formValues.automaticallyAssignVendor,
        });
        break;

      case CreateProspectingCurrentStep.preview:
        if (formValues.cascades === undefined) {
          await onExecuteProspecting({
            prospectingId: prospectingDataResponse?.data.prospecting.id,
            expiryAt: formatISO(new Date(formValues.expiryAt)),
            cascades: [],
          });
          break;
        }

        const editedCascades = formValues.cascades.map((cascade, index) => ({
          position: index,
          lifespan: convertDelayTimeToMiliseconds(cascade.lifespan) || 0,
          immortal: cascade.immortal ? cascade.immortal : false,
        }));

        await onExecuteProspecting({
          prospectingId: prospectingDataResponse?.data.prospecting.id,
          expiryAt: formatISO(new Date(formValues.expiryAt)),
          cascades: editedCascades,
        });
        break;
    }
  };

  const handlePreviousStepClick = async () => {
    if (currentStep === CreateProspectingCurrentStep.preview) {
      setCurrentStep(CreateProspectingCurrentStep.setup);
    }
  };

  useEffect(() => {
    prospectingDataResponse?.data.prospecting.job.data.number &&
      changeAppTitle({
        onRoute: AppRoute.prospectingWizard,
        title: `Prospecting ${prospectingDataResponse?.data.prospecting.job.data.number}`,
      });
  }, [prospectingDataResponse]);

  return (
    <CreateProspecting
      currentStep={currentStep}
      onNextStepClick={handleNextStepClick}
      onPreviousStepClick={handlePreviousStepClick}
      jobDetails={prospectingDataResponse?.data.prospecting.job.data}
      prospecting={prospectingDataResponse?.data.prospecting}
      prospectingStrategyShortList={prospectingStrategyShortList}
      alreadyExistingProspectingId={prospectingDataResponse?.data.prospectingAlreadyExists}
      isLoadingUpdateProspecting={isLoadingUpdateProspecting}
      isLoadingExecuteProspecting={isLoadingExecuteProspecting}
      isSuccessExecuteProspecting={isSuccessExecuteProspecting}
      isLoadingProspectingData={isLoading || isFetching}
    />
  );
};
