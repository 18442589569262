import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';

import { ProspectingAlreadyExistsCardProps } from './ProspectingAlreadyExistsCard.types';
import styles from './ProspectingAlreadyExistsCard.module.scss';

export const ProspectingAlreadyExistsCard = ({
  showPreviousJobProspecting,
  newProspectingId,
  onCancelExistingJobProspecting,
  isLoadingCancelExistingJobProspecting,
  isModalOpen,
  openModal,
  closeModal,
}: ProspectingAlreadyExistsCardProps) => (
  <>
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <Typography.Title level={5}>
          <FormattedMessage id="createProspecting.prospectingAlreadyExistsCard.title" />
        </Typography.Title>

        <Typography.Text className={styles.label}>
          <FormattedMessage id="createProspecting.prospectingAlreadyExistsCard.label" />
        </Typography.Text>

        <div className={styles.buttonsWrapper}>
          <Button data-testid="showProspectingButton" type="primary" onClick={showPreviousJobProspecting}>
            <FormattedMessage id="createProspecting.buttons.showJobProspecting" />
          </Button>

          <Button data-testid="startNewProspectingButton" type="default" onClick={openModal}>
            <FormattedMessage id="createProspecting.buttons.startNewProspecting" />
          </Button>
        </div>
      </div>
    </div>

    <ConfirmModal
      isOpen={isModalOpen}
      onCancel={closeModal}
      onSubmit={() => {
        onCancelExistingJobProspecting({
          prospectingId: newProspectingId,
        });
      }}
      text={<FormattedMessage id="createProspecting.prospectingAlreadyExistsCard.modal.label" />}
      submitButtonText={<FormattedMessage id="createProspecting.prospectingAlreadyExistsCard.modal.submit" />}
      isLoadingSubmitButton={isLoadingCancelExistingJobProspecting}
      cancelButtonText={<FormattedMessage id="createProspecting.prospectingAlreadyExistsCard.modal.cancel" />}
    />
  </>
);
