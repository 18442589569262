import { ast } from 'peggy';

export type Sort = {
  id: string;
  option: SortingOptions;
  function: SortingFunctions;
};

export type SimpleSortingProps = {
  addedSorting: Sort[];
  onAddSorting: (sortBy: SortingOptions) => void;
  onDeleteSorting: (addedSortingId: Sort['id']) => void;
  onChangeMainSorting: (selected: SortingOptions, addedSortingId: Sort['id']) => void;
  onChangeFunction: (selectedFunction: SortingFunctions, addedSortingId: Sort['id']) => void;
};

export type SimpleSortingContainerProps = {
  addedSorting: Sort[];
  setAddedSorting: (sorting: Sort[]) => void;
};

export enum SortingOptions {
  individual = 'individual',
  inHouse = 'inHouse',
  preferredByClient = 'preferredByClient',
  nativeLanguage = 'nativeLanguage',
  country = 'country',
  evaluation = 'evaluation',
  lastJobDate = 'lastJobDate',
  lastJobDateForThisClient = 'lastJobDateForThisClient',
  workedForThisClient = 'workedForThisClient',
}

export enum SortingOperators {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum BooleanFunctions {
  yesFirst = 'yesFirst',
  noFirst = 'noFirst',
}

export enum DateFunctions {
  fromEarliest = 'fromEarliest',
  fromLatest = 'fromLatest',
}

export enum StringFunctions {
  fromAtoZ = 'fromAtoZ',
  fromZtoA = 'fromZtoA',
}

export enum NumberFunctions {
  fromHighest = 'fromHighest',
  fromLowest = 'fromLowest',
}

export enum ParserPropertyToFunctionTranslation {
  INDIVIDUAL_ASC = 'noFirst',
  INDIVIDUAL_DESC = 'yesFirst',
  IN_HOUSE_ASC = 'noFirst',
  IN_HOUSE_DESC = 'yesFirst',
  PREFERRED_BY_CLIENT_ASC = 'noFirst',
  PREFERRED_BY_CLIENT_DESC = 'yesFirst',
  NATIVE_LANGUAGE_ASC = 'noFirst',
  NATIVE_LANGUAGE_DESC = 'yesFirst',
  COUNTRY_ASC = 'fromAtoZ',
  COUNTRY_DESC = 'fromZtoA',
  EVALUATION_ASC = 'fromLowest',
  EVALUATION_DESC = 'fromHighest',
  LAST_JOB_DATE_ASC = 'fromEarliest',
  LAST_JOB_DATE_DESC = 'fromLatest',
  LAST_JOB_DATE_FOR_THIS_CLIENT_ASC = 'fromEarliest',
  LAST_JOB_DATE_FOR_THIS_CLIENT_DESC = 'fromLatest',
  WORKED_FOR_THIS_CLIENT_ASC = 'noFirst',
  WORKED_FOR_THIS_CLIENT_DESC = 'yesFirst',
}

export type SortingFunctions = BooleanFunctions | DateFunctions | StringFunctions | NumberFunctions;

export enum SortingFunctionsOrder {
  yesFirst = 'desc',
  noFirst = 'asc',
  fromEarliest = 'asc',
  fromLatest = 'desc',
  fromAtoZ = 'asc',
  fromZtoA = 'desc',
  fromHighest = 'desc',
  fromLowest = 'asc',
}

export enum SortingOptionsMessages {
  individual = 'prospectingCreator.simpleFilters.individual',
  inHouse = 'prospectingCreator.simpleFilters.inHouse',
  preferredByClient = 'prospectingCreator.simpleFilters.preferredByClient',
  nativeLanguage = 'prospectingCreator.simpleFilters.nativeLanguage',
  country = 'prospectingCreator.simpleFilters.country',
  evaluation = 'prospectingCreator.simpleFilters.evaluation',
  lastJobDate = 'prospectingCreator.simpleFilters.lastJobDate',
  lastJobDateForThisClient = 'prospectingCreator.simpleFilters.lastJobDateForThisClient',
  workedForThisClient = 'prospectingCreator.simpleFilters.workedForThisClient',
}

export enum SortingFunctionsMessages {
  yesFirst = 'prospectingCreator.simpleSorting.yesFirst',
  noFirst = 'prospectingCreator.simpleSorting.noFirst',
  fromEarliest = 'prospectingCreator.simpleSorting.fromEarliest',
  fromLatest = 'prospectingCreator.simpleSorting.fromLatest',
  fromAtoZ = 'prospectingCreator.simpleSorting.fromAtoZ',
  fromZtoA = 'prospectingCreator.simpleSorting.fromZtoA',
  fromHighest = 'prospectingCreator.simpleSorting.fromHighest',
  fromLowest = 'prospectingCreator.simpleSorting.fromLowest',
}

export enum ParseProperties {
  INDIVIDUAL = 'INDIVIDUAL',
  IN_HOUSE = 'IN_HOUSE',
  PREFERRED_BY_CLIENT = 'PREFERRED_BY_CLIENT',
  NATIVE_LANGUAGE = 'NATIVE_LANGUAGE',
  COUNTRY = 'COUNTRY',
  EVALUATION = 'EVALUATION',
  LAST_JOB_DATE = 'LAST_JOB_DATE',
  LAST_JOB_DATE_FOR_THIS_CLIENT = 'LAST_JOB_DATE_FOR_THIS_CLIENT',
  WORKED_FOR_THIS_CLIENT = 'WORKED_FOR_THIS_CLIENT',
}

export type ParseExpression = {
  operator: SortingOperators;
  property?: ParseProperties;
  left?: ParseExpression;
  right?: ParseExpression;
};

export type ParseOutput = ast.Grammar & ParseExpression;

export enum QueryStringProperties {
  INDIVIDUAL = 'individual',
  IN_HOUSE = 'inHouse',
  PREFERRED_BY_CLIENT = 'preferredByClient',
  NATIVE_LANGUAGE = 'nativeLanguage',
  COUNTRY = 'country',
  EVALUATION = 'evaluation',
  LAST_JOB_DATE = 'lastJobDate',
  LAST_JOB_DATE_FOR_THIS_CLIENT = 'lastJobDateForThisClient',
  WORKED_FOR_THIS_CLIENT = 'workedForThisClient',
}
