import { Tooltip } from 'antd';

import { Language } from './language/Language';
import { LanguagesProps } from './Languages.types';

export const Languages = ({ languages }: LanguagesProps) => (
  <>
    {languages.length < 2 ? (
      languages.map((language, index) => <Language key={language} name={language.toUpperCase()} index={index} />)
    ) : (
      <Tooltip
        placement="top"
        title={languages.map((language, index) => (
          <Language key={language} name={language.toUpperCase()} index={index} />
        ))}
      >
        <span>{`${languages[0].toUpperCase()}…`}</span>
      </Tooltip>
    )}
  </>
);
