import { memo } from 'react';

import { useFiltersMethods } from '../hooks/useFiltersMethods';

import { SimpleFiltersContainerProps } from './SimpleFilters.types';
import { SimpleFilters } from './SimpleFilters';

export const SimpleFiltersContainer = memo(({ filters, setFilters }: SimpleFiltersContainerProps) => {
  const { addFilter, deleteFilter, changeFilterType, changeFilterFunction, changeFilterValue } = useFiltersMethods({
    filters,
    setFilters,
  });

  return (
    <SimpleFilters
      filters={filters}
      onAddFilter={addFilter}
      onDeleteFilter={deleteFilter}
      onChangeFilterType={changeFilterType}
      onChangeFilterFunction={changeFilterFunction}
      onChangeFilterValue={changeFilterValue}
    />
  );
});
