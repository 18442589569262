import { ProspectingStrategy } from 'api/prospectingStrategies/prospectingStrategies.types';
import {
  CascadeStatus,
  NegotiationStrategies,
  ProspectingState,
  ProspectingStatus,
  RankingStrategies,
  VendorOfferStatus,
} from 'core/global.enum';

export type JobDetails = {
  data: {
    name: string;
    number: string;
    sourceLanguages: string[];
    targetLanguages: string[];
    services?: Array<{
      finance?: {
        calculationUnit?: string;
        quantity?: {
          totalQuantity?: number;
        };
      };
      serviceSpecification: {
        nativeLanguage: string;
        specialization: {
          notLocalized: string;
        };
      };
    }>;
    dates?: {
      plannedStartDate?: string;
      plannedEndDate?: string;
    };
    finance: {
      currency: string;
    };
    specialization?: {
      notLocalized: string;
    };
  };
  assignedVendor?: AssignedVendor;
};

export type AssignedVendor = {
  id: string;
  name: string;
  tmsVendorId: string;
};

export type VendorOffer = {
  status: VendorOfferStatus;
  rank?: number;
  deadline?: string;
  respondedAt?: string;
  name: string;
  vendorId: string;
  position: number;
  projectCurrency: string;
  chatroomId?: string;
  earlyFinished?: boolean;
  exchangeRateFeeError?: string;
  calculatedFee?: number;
  isRecommended?: boolean;
};

export type Cascade = {
  sentAt: string;
  immortal: boolean;
  rankingStrategy: RankingStrategies;
  negotiationStrategy: NegotiationStrategies;
  status: CascadeStatus;
  vendorOffers: VendorOffer[];
  lifespan: number;
  position: number;
};

export type Prospecting = {
  id: string;
  prospectingStrategy?: ProspectingStrategy;
  job: JobDetails;
  workspaceId: string;
  status: ProspectingStatus;
  state: ProspectingState;
  cascades: Cascade[];
  automaticallyAssignVendor: boolean;
  assignedVendorId?: string;
  createdAt: string;
  additionalLifespan: number;
  projectCurrency: string;
  preparedAt?: string;
  startedAt?: string;
  expiryAt?: string;
  finishedAt?: string;
  managersChatroomId?: string;
};

export type ProspectingDataResponse = {
  data: {
    prospecting: Prospecting;
    prospectingAlreadyExists?: string;
  };
};

export type PrepareProspectingPayload = {
  id: string;
  automaticallyAssignVendor: boolean;
  prospectingStrategyId: string;
};

export type PrepareProspectingResponse = {
  data: {
    id: string;
    prospectingStrategyId: string;
    startedAt: string;
    status: ProspectingStatus;
    cascades: Cascade[];
  };
};

export type ExecuteProspectingPayload = {
  prospectingId: string;
  expiryAt: string;
  cascades: {
    position: number;
    lifespan: number;
    immortal: boolean;
  }[];
};

export type AssignVendorToJobPayload = {
  prospectingId: string;
  vendorId: string;
};

export type AssignVendorToJobResponse = Record<string, never>;

export type ReassignVendorToJobPayload = AssignVendorToJobPayload;

export type ReassignVendorToJobResponse = AssignVendorToJobResponse;

export type ExecuteProspectingResponse = Record<string, never>;

type OneOfJob = {
  number: string;
  sourceLanguages: string[];
  targetLanguages: string[];
};

export type ProspectingListResponse = {
  data: ProspectingListElement[];
  oneOf: {
    jobs: OneOfJob[];
    status: string[];
  };
  length: number;
};

export type ProspectingListElement = {
  prospectingId: string;
  clientName: string;
  jobNumber: string;
  projectId: string;
  projectName: string;
  startedAt?: string;
  finishedAt?: string;
  state: ProspectingState;
  status: ProspectingStatus;
  targetLanguages?: Array<string>;
  sourceLanguages?: Array<string>;
  managerNames: string;
};

export enum ClientNameSort {
  ASC = 'clientName',
  DESC = '-clientName',
}

export enum ProjectNameSort {
  ASC = 'projectName',
  DESC = '-projectName',
}

export enum JobNumberSort {
  ASC = 'jobNumber',
  DESC = '-jobNumber',
}

export enum StartedAtSort {
  ASC = 'startedAt',
  DESC = '-startedAt',
}

export enum FinishedAtSort {
  ASC = 'finishedAt',
  DESC = '-finishedAt',
}

export enum StatusSort {
  ASC = 'status',
  DESC = '-status',
}

export enum ManagerNamesSort {
  ASC = 'managerNames',
  DESC = '-managerNames',
}

export type ProspectingListQuerySort =
  | ClientNameSort
  | ProjectNameSort
  | JobNumberSort
  | StartedAtSort
  | FinishedAtSort
  | StatusSort
  | ManagerNamesSort;

export type ProspectingListQuery = {
  skip?: number;
  take?: number;
  jobNumbers?: string[];
  status?: string[];
  sort?: ProspectingListQuerySort;
};

export type ProspectingListPayload = {
  oneOf: {
    jobNumber?: string[];
    status?: string[];
  };
  sort?: ProspectingListQuerySort;
  skip?: number;
  take?: number;
};

export type CreateTestProspectingPayload = {
  jobId: string;
};

export type CreateTestProspectingResponse = {
  prospecting: Prospecting;
};

export type CancelExistingJobProspectingPayload = {
  prospectingId: string;
};
