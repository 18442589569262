import { Col, Row } from 'antd';

import { Loader } from 'ui/loader/Loader';
import { JobDetailsCard } from 'ui/jobDetailsCard/JobDetailsCard';

import { CascadeCard } from './cascadeCard/CascadeCard';
import { JobProspectingStatusPageProps } from './JobProspectingStatusPage.types';
import { JobProspectingStatusPageHeader } from './jobProspectingStatusPageHeader/JobProspectingStatusPageHeader';
import styles from './JobProspectingStatusPage.module.scss';

export const JobProspectingStatusPage = ({
  prospectingData,
  isLoading,
  areVisibleAssignVendorButtons,
  setAreVisibleAssignVendorButtons,
}: JobProspectingStatusPageProps) => {
  const assignedVendor = prospectingData?.prospecting.job.assignedVendor;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <JobProspectingStatusPageHeader
        prospectingData={prospectingData}
        areVisibleAssignVendorButtons={areVisibleAssignVendorButtons}
        setAreVisibleAssignVendorButtons={setAreVisibleAssignVendorButtons}
        assignedVendor={assignedVendor}
      />

      <Row className={styles.details}>
        <Col span={24}>
          <JobDetailsCard jobDetails={prospectingData?.prospecting.job.data} />
        </Col>

        {prospectingData?.prospecting.cascades
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map((cascade, index) => (
            <CascadeCard
              key={index}
              prospecting={prospectingData?.prospecting}
              areVisibleAssignVendorButtons={areVisibleAssignVendorButtons}
              cascade={cascade}
              cascadeNumber={index + 1}
              jobNumber={prospectingData?.prospecting.job.data.number}
            />
          ))}
      </Row>
    </div>
  );
};
