import { v4 as uuidv4 } from 'uuid';

import {
  SortingOptions,
  BooleanFunctions,
  StringFunctions,
  NumberFunctions,
  DateFunctions,
  SimpleSortingContainerProps,
  SortingFunctions,
  Sort,
} from './SimpleSorting.types';
import { SimpleSorting } from './SimpleSorting';

export const SORTING_FUNCTIONS = {
  individual: BooleanFunctions,
  inHouse: BooleanFunctions,
  preferredByClient: BooleanFunctions,
  nativeLanguage: BooleanFunctions,
  country: StringFunctions,
  evaluation: NumberFunctions,
  lastJobDate: DateFunctions,
  lastJobDateForThisClient: DateFunctions,
  workedForThisClient: BooleanFunctions,
};

export const SimpleSortingContainer = ({ addedSorting, setAddedSorting }: SimpleSortingContainerProps) => {
  const onAddSorting = (option: SortingOptions) => {
    setAddedSorting([
      ...addedSorting,
      {
        id: uuidv4(),
        option,
        function: Object.values(SORTING_FUNCTIONS[option])[0],
      },
    ]);
  };

  const onDeleteSorting = (addedSortingId: Sort['id']) => {
    setAddedSorting(addedSorting.filter((addedFilter) => addedFilter.id !== addedSortingId));
  };

  const onChangeMainSorting = (selected: SortingOptions, addedSortingId: Sort['id']) => {
    setAddedSorting([
      ...addedSorting.map((addedSort) =>
        addedSort.id === addedSortingId
          ? {
              ...addedSort,
              option: selected,
              function: Object.values(SORTING_FUNCTIONS[selected])[0],
            }
          : addedSort,
      ),
    ]);
  };

  const onChangeFunction = (selected: SortingFunctions, addedSortingId: Sort['id']) => {
    setAddedSorting([
      ...addedSorting.map((addedFilter) =>
        addedFilter.id === addedSortingId
          ? {
              ...addedFilter,
              function: selected,
            }
          : addedFilter,
      ),
    ]);
  };

  return (
    <SimpleSorting
      addedSorting={addedSorting}
      onAddSorting={onAddSorting}
      onDeleteSorting={onDeleteSorting}
      onChangeMainSorting={onChangeMainSorting}
      onChangeFunction={onChangeFunction}
    />
  );
};
