import { FormattedMessage } from 'react-intl';
import { Col, Row, Space } from 'antd';

import { XtrfCard } from 'ui/xtrfCard/XtrfCard';
import { formatDate } from 'helpers/formatDate';
import { NO_VALUE_SYMBOL } from 'core/global.constants';

import { JobDetailsCardProps } from './JobDetailsCard.types';

// eslint-disable-next-line @typescript-eslint/no-var-requires

export const JobDetailsCard = ({ jobDetails }: JobDetailsCardProps) => {
  const getLanguageCombinations = () => {
    if (!jobDetails?.sourceLanguages?.length || !jobDetails?.targetLanguages?.length) {
      return NO_VALUE_SYMBOL;
    }
    const sourceLanguage = jobDetails?.sourceLanguages.length
      ? jobDetails?.sourceLanguages.map((language) => `${language.toUpperCase()}`).join(', ')
      : NO_VALUE_SYMBOL;
    const targetLanguages = jobDetails?.targetLanguages.length
      ? jobDetails?.targetLanguages.map((language) => `${language.toUpperCase()}`).join(', ')
      : NO_VALUE_SYMBOL;

    return `${sourceLanguage} ⇨ ${targetLanguages}`;
  };

  const getVolumeData = () => {
    return jobDetails?.services?.filter((s) => s.finance && s.finance.calculationUnit && s.finance.quantity).length
      ? jobDetails?.services
          .filter((s) => s.finance && s.finance.calculationUnit && s.finance.quantity)
          .map((s) => `${s.finance?.quantity?.totalQuantity} ${s.finance?.calculationUnit}`)
          .join(', ')
      : NO_VALUE_SYMBOL;
  };

  const structure = {
    left: [
      {
        label: 'prospectingDetails.jobDetailsCard.jobType',
        value: jobDetails?.name,
        'data-testid': 'jobDetailsJobType',
      },
      {
        label: 'prospectingDetails.jobDetailsCard.languages',
        value: getLanguageCombinations(),
        'data-testid': 'jobDetailsLanguages',
      },
      {
        label: 'prospectingDetails.jobDetailsCard.specialization',
        value: jobDetails?.services?.length
          ? jobDetails.services.map((el) => el.serviceSpecification.specialization.notLocalized).join(', ')
          : jobDetails?.specialization?.notLocalized || NO_VALUE_SYMBOL,
        'data-testid': 'jobDetailsSpecialization',
      },
    ],
    right: [
      {
        label: 'prospectingDetails.jobDetailsCard.volume',
        value: getVolumeData(),
        'data-testid': 'jobDetailsVolume',
      },
      {
        label: 'prospectingDetails.jobDetailsCard.plannedStartDate',
        value: jobDetails?.dates?.plannedStartDate ? formatDate(jobDetails?.dates?.plannedStartDate) : NO_VALUE_SYMBOL,
        'data-testid': 'jobDetailsPlannedStartDate',
      },
      {
        label: 'prospectingDetails.jobDetailsCard.deadline',
        value: jobDetails?.dates?.plannedEndDate ? formatDate(jobDetails?.dates?.plannedEndDate) : NO_VALUE_SYMBOL,
        'data-testid': 'jobDetailsPlannedEndDate',
      },
    ],
  };
  return (
    <XtrfCard
      title={<FormattedMessage id="prospectingDetails.jobDetailsCard.title" values={{ jobName: jobDetails?.number }} />}
      data-testid="jobDetailsCard"
      children={
        <Row justify="start" gutter={[24, 24]}>
          <Col>
            {structure.left.map((el, index) => (
              <Row key={index} style={{ minHeight: '25px' }} data-testid={el['data-testid']}>
                <Space>
                  <FormattedMessage tagName={'b'} id={el.label} />
                  {el.value}
                </Space>
              </Row>
            ))}
          </Col>
          <Col>
            {structure.right.map((el, index) => (
              <Row key={index} style={{ minHeight: '25px' }} data-testid={el['data-testid']}>
                <Space>
                  <FormattedMessage tagName={'b'} id={el.label} />
                  {el.value}
                </Space>
              </Row>
            ))}
          </Col>
        </Row>
      }
    />
  );
};
