import { AppRoute } from 'routing/AppRoute.enum';

type ChangeAppTitleParams = { onRoute: AppRoute; title: string };

export const changeAppTitle = (params: ChangeAppTitleParams | ChangeAppTitleParams[]) => {
  const appTitleElement = document.getElementById('appTitle');

  if (!appTitleElement) return;

  if (Array.isArray(params)) {
    params.forEach(({ onRoute, title }) => {
      if (!window.location.pathname.includes(onRoute)) return;

      appTitleElement.innerHTML = `XTRF Chat - ${title}`;
    });
  } else {
    if (!window.location.pathname.includes(params.onRoute)) return;

    appTitleElement.innerHTML = `XTRF Chat - ${params.title}`;
  }
};
