import { CaretRightOutlined } from '@ant-design/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { Languages } from './languages/Languages';
import { FilterLanguagesProps } from './FilterLanguages.types';
import styles from './FilterLanguages.module.scss';

export const FilterLanguages = ({ sourceLanguages, targetLanguages }: FilterLanguagesProps) => {
  const { formatMessage } = useLocale();

  return (
    <div>
      {sourceLanguages.length === 0 || targetLanguages.length === 0 ? (
        <span className={styles.noLanguagesMessage}>
          {formatMessage({ id: AppMessages['jobProspectingDashboard.filters.noLanguages'] })}
        </span>
      ) : (
        <>
          <Languages languages={sourceLanguages} />
          <CaretRightOutlined className={styles.icon} />
          <Languages languages={targetLanguages} />
        </>
      )}
    </div>
  );
};
