import {
  BooleanFunctions,
  BooleanItems,
  CategoriesFunctions,
  CountryFunctions,
  DateFunctions,
  DateItems,
  EvaluationFunctions,
  ToolsFunctions,
  FilterValueTypes,
} from './SimpleFilters.types';

export const FILTERING_FUNCTIONS = {
  individual: BooleanFunctions,
  inHouse: BooleanFunctions,
  preferredByClient: BooleanFunctions,
  nativeLanguage: BooleanFunctions,
  country: CountryFunctions,
  evaluation: EvaluationFunctions,
  tools: ToolsFunctions,
  lastJobDate: DateFunctions,
  lastJobDateForThisClient: DateFunctions,
  workedForThisClient: BooleanFunctions,
  categories: CategoriesFunctions,
};

export const FILTERING_ITEMS = {
  individual: BooleanItems,
  inHouse: BooleanItems,
  preferredByClient: BooleanItems,
  nativeLanguage: BooleanItems,
  country: FilterValueTypes.string,
  evaluation: FilterValueTypes.number,
  tools: FilterValueTypes.string,
  lastJobDate: DateItems,
  lastJobDateForThisClient: DateItems,
  workedForThisClient: BooleanItems,
  categories: FilterValueTypes.string,
};
