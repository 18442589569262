import { getTime, intervalToDuration } from 'date-fns';

export const useFormatDate = (date: string | undefined, expiryAt: string | undefined) => {
  const expiryAtTimestamp = getTime(new Date(expiryAt ?? 0));
  const dateTimestamp = getTime(new Date(date ?? 0));

  if (dateTimestamp < expiryAtTimestamp) return '';

  const { days, hours, minutes } = intervalToDuration({
    start: new Date(expiryAt ?? 0),
    end: new Date(date ?? 0) ?? 0,
  });

  const formattedDays = days ? `${days}d ` : '';
  const formattedHours = hours ? `${hours}h ` : '';
  const formattedMinutes = minutes ? `${minutes}m` : '';
  const result = formattedDays + formattedHours + formattedMinutes;

  return result;
};
