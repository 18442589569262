import clsx from 'clsx';

import { useLocale } from 'hooks/useLocale/useLocale';
import { ProspectingState, ProspectingStatus, VendorOfferStatus } from 'core/global.enum';
import { Status } from 'core/global.types';

import styles from './StatusLabel.module.scss';
import { StatusLabelProps } from './StatusLabel.types';

export const StatusLabel = ({ status }: StatusLabelProps) => {
  const { formatMessage } = useLocale();
  const statusLabelColors = new Map<Status, string>([
    [VendorOfferStatus.rejected, 'red'],
    [VendorOfferStatus.accepted, 'green'],
    [VendorOfferStatus.noResponse, 'gray'],
    [VendorOfferStatus.pending, 'blue'],
    [VendorOfferStatus.notSent, 'gray'],
    [VendorOfferStatus.sent, 'blue'],
    [VendorOfferStatus.rejecting, 'blue'],
    [VendorOfferStatus.prepared, 'gray'],
    [VendorOfferStatus.negotiated, 'green'],
    [VendorOfferStatus.awaitingTMSResponse, 'green'],
    [VendorOfferStatus.vendorAssignedTMSConfirmed, 'green'],
    [ProspectingStatus.inProgress, 'yellow'],
    [ProspectingStatus.finished, 'green'],
    [ProspectingStatus.cancelled, 'gray'],
    [ProspectingStatus.recommendedVendorSelected, 'green'],
    [ProspectingStatus.vendorFound, 'green'],
    [ProspectingStatus.noRecommendation, 'red'],
    [ProspectingStatus.noProposalReceived, 'red'],
    [ProspectingStatus.otherVendorSelected, 'green'],
    [ProspectingState.assigned, 'green'],
  ]);
  const statusColor = (status: Status): string => statusLabelColors.get(status) || 'blue';

  return (
    <div className={clsx(styles.container, styles[statusColor(status)])}>
      {formatMessage({
        id: `status.${status}`,
      })}
    </div>
  );
};
