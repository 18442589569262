import { range } from 'lodash';
import { getYear } from 'date-fns';

export const years = range(getYear(new Date()), getYear(new Date()) + 6, 1);
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type Options = {
  dateStyle: 'time' | 'date' | 'full';
};

export const formatDateWithTimeZone = (date: string, { dateStyle }: Options): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const dateOptions = {
    full: {
      dateStyle: 'short',
      timeStyle: 'long',
    },
    time: {
      dateStyle: undefined,
      timeStyle: 'short',
    },
    date: {
      dateStyle: 'short',
      timeStyle: undefined,
    },
  }[dateStyle];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new Intl.DateTimeFormat(locale, {
    timeZone,
    ...dateOptions,
  }).format(new Date(date));
};
