export enum QueryKey {
  PROSPECTING_STRATEGIES = 'PROSPECTING_STRATEGIES',
  PROSPECTING_DATA = 'PROSPECTING_DATA',
  PREPARE_PROSPECTING = 'PREPARE_PROSPECTING',
  EXECUTE_PROSPECTING = 'EXECUTE_PROSPECTING',
  CANCEL_PROSPECTING = 'CANCEL_PROSPECTING',
  ASSIGN_VENDOR = 'ASSIGN_VENDOR',
  REASSIGN_VENDOR = 'REASSIGN_VENDOR',
  PROSPECTING_LIST = 'PROSPECTING_LIST',
  CREATE_TEST_PROSPECTING = 'CREATE_TEST_PROSPECTING',
  CREATE_TEST_JOB = 'CREATE_TEST_JOB',
  CANCEL_EXISTING_JOB_PROSPECTING = 'CANCEL_EXISTING_JOB_PROSPECTING',
  PROSPECTING_STRATEGY_SHORT_LIST = 'PROSPECTING_STRATEGY_SHORT_LIST',
}

export enum ProspectingStatus {
  created = 'created',
  prepared = 'prepared',
  inProgress = 'inProgress',
  vendorFound = 'vendorFound',
  noRecommendation = 'noRecommendation',
  noProposalReceived = 'noProposalsReceived',
  recommendedVendorSelected = 'recommendedVendorSelected',
  otherVendorSelected = 'otherVendorSelected',
  cancelled = 'cancelled',
  finished = 'finished',
}

export enum ProspectingState {
  created = 'created',
  prepared = 'prepared',
  inProgress = 'inProgress',
  finished = 'finished',
  cancelled = 'cancelled',
  assigned = 'assigned',
}

export enum VendorOfferStatus {
  accepted = 'accepted',
  rejected = 'rejected',
  rejecting = 'rejecting',
  sent = 'sent',
  negotiation = 'negotiation',
  created = 'prepared',
  noResponse = 'noResponse',
  notSent = 'notSent',
  negotiated = 'negotiated',
  pending = 'pending',
  prepared = 'prepared',
  vendorAssignedTMSConfirmed = 'vendorAssignedTMSConfirmed',
  awaitingTMSResponse = 'awaitingTMSResponse',
}

export enum CascadeStatus {
  created = 'created',
  sent = 'sent',
  abandon = 'abandon',
  finished = 'finished',
}

export enum ProspectingPlanStatus {
  inProgress = 'inProgress',
  executed = 'executed',
}

export enum RankingStrategies {
  firstOneWin = 'firstOneWin',
  cheapestAtDeadline = 'cheapestAtDeadline',
}

export enum RankingStrategiesMessages {
  firstOneWin = 'prospectingCreator.cascade.rankingStrategy.firstOneWins',
  cheapestAtDeadline = 'prospectingCreator.cascade.rankingStrategy.cheapestAtDeadline',
}

export enum NegotiationStrategiesDescription {
  negotiation = 'prospectingCreator.cascade.negotiationStrategy.note.negotiation',
  noNegotiation = 'prospectingCreator.cascade.negotiationStrategy.note.noNegotiation',
}

export enum RankingStrategiesDescription {
  firstOneWin = 'prospectingCreator.cascade.rankingStrategy.note.firstOneWins',
  cheapestAtDeadline = 'prospectingCreator.cascade.rankingStrategy.note.cheapestAtDeadline',
}

export enum NegotiationStrategies {
  negotiation = 'negotiation',
  noNegotiation = 'noNegotiation',
}

export enum NegotiationStrategiesMessages {
  negotiation = 'prospectingCreator.cascade.negotiationStrategy.negotiation',
  noNegotiation = 'prospectingCreator.cascade.negotiationStrategy.noNegotiation',
}
