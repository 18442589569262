import { useState, useEffect } from 'react';
import { Button, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Header } from 'ui/header/Header';
import { AppMessages } from 'i18n/messages';
import { Loader } from 'ui/loader/Loader';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';
import { AppRoute } from 'routing/AppRoute.enum';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';

import { GeneralSettings } from './generalSettings/GeneralSettings';
import { CascadeContainer } from './cascade/CascadeContainer';
import { ProspectingStrategyCreatorProps } from './ProspectingStrategyCreator.types';
import styles from './ProspectingStrategyCreator.module.scss';

export const ProspectingStrategyCreator = ({
  isLoading,
  fields,
  onSubmit,
  duplicateCascade,
  removeCascade,
  addCascade,
  onEndDrag,
  isLoadingAddProspecting,
  isLoadingEditProspecting,
  deleteProspectingStrategy,
}: ProspectingStrategyCreatorProps) => {
  const { formatMessage } = useLocale();
  const history = useCustomHistory();

  const [isOpenBackConfirmModal, setIsOpenBackConfirmModal] = useState(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useFormContext();

  const onClickBackButton = () => {
    if (isDirty) {
      setIsOpenBackConfirmModal(true);
    } else {
      history.push({
        route: AppRoute.prospectingStrategiesList,
      });
    }
  };

  useEffect(() => {
    if (!fields.length) addCascade();
  }, [addCascade, fields.length]);

  if (isLoading) return <Loader />;

  return (
    <section className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="strategyForm">
        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field }) => (
            <Header
              beforeTitleAddon={
                <Button
                  onClick={onClickBackButton}
                  data-testid="backButton"
                  className={styles.backToListButton}
                  icon={<LeftOutlined />}
                >
                  {formatMessage({
                    id: AppMessages['prospectingDetails.backButton'],
                  })}
                </Button>
              }
              data-testid="prospectingStrategyCreatorTitle"
              renderCustomTitle={
                <input
                  {...field}
                  className={clsx(styles.editableHeaderInput, {
                    [styles.editableHeaderInputError]: !!errors['name']?.message,
                  })}
                  placeholder={
                    !!errors['name']?.message
                      ? formatMessage({ id: AppMessages['prospectingCreator.editTitlePlaceholder.validationMessage'] })
                      : formatMessage({ id: AppMessages['prospectingCreator.editTitlePlaceholder'] })
                  }
                />
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                className={styles.saveButton}
                data-testid="saveButton"
                disabled={isLoadingAddProspecting || isLoadingEditProspecting}
              >
                {formatMessage({ id: AppMessages['prospectingCreator.saveButton'] })}
              </Button>

              {/* 
                - UNCOMMENT WHEN ACTION DELETE STRATEGY WILL BE DONE (BACKEND)
                - IN jobProspecting.ts - CHECK IF THERE IS CORRECT ENDPOINT FOR THIS ACTION -> removeProspectingStrategy
              */}
              {/* {prospectingId && (
                <Button onClick={() => setIsOpenDeleteConfirmModal(true)} data-testid="deleteButton">
                  {formatMessage({ id: AppMessages['prospectingCreator.deleteButton'] })}
                </Button>
              )} */}
            </Header>
          )}
        />

        <GeneralSettings />

        <DragDropContext onDragEnd={onEndDrag}>
          <Droppable droppableId="column">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map(
                  (cascade, index) =>
                    cascade.cascadeFormId && (
                      <CascadeContainer
                        key={cascade.cascadeFormId}
                        cascade={cascade}
                        index={index}
                        cascadeFormId={cascade.cascadeFormId}
                        removeCascade={() => removeCascade(index)}
                        duplicateCascade={duplicateCascade}
                        isLastCasdade={fields.length - 1 === index}
                        numberOfCascades={fields.length}
                      />
                    ),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Space direction="vertical" className={styles.buttonsWrapper}>
          <div className={styles.addCascadeButtonWrapper}>
            <Button onClick={addCascade} data-testid="addCascadeButton">
              {!fields.length
                ? formatMessage({ id: AppMessages['prospectingCreator.addCascadeButton'] })
                : formatMessage({ id: AppMessages['prospectingCreator.addAnotherCascadeButton'] })}
            </Button>
          </div>
        </Space>
      </form>

      <ConfirmModal
        isOpen={isOpenBackConfirmModal}
        onCancel={() => setIsOpenBackConfirmModal(false)}
        onSubmit={() =>
          history.push({
            route: AppRoute.prospectingStrategiesList,
          })
        }
        title={formatMessage({ id: AppMessages['prospectingCreator.modal.back.title'] })}
        text={formatMessage({ id: AppMessages['prospectingCreator.modal.back.text'] })}
        submitButtonText={formatMessage({ id: AppMessages['prospectingCreator.modal.back.submitButton'] })}
        cancelButtonText={formatMessage({ id: AppMessages['prospectingCreator.modal.back.cancelButton'] })}
      />

      <ConfirmModal
        isOpen={isOpenDeleteConfirmModal}
        onCancel={() => setIsOpenDeleteConfirmModal(false)}
        onSubmit={deleteProspectingStrategy}
        title={formatMessage({ id: AppMessages['prospectingCreator.modal.delete.title'] })}
        text={formatMessage({ id: AppMessages['prospectingCreator.modal.delete.text'] })}
        submitButtonText={formatMessage({ id: AppMessages['prospectingCreator.modal.delete.submitButton'] })}
        cancelButtonText={formatMessage({ id: AppMessages['prospectingCreator.modal.delete.cancelButton'] })}
        data-testid="confirmDeleteModal"
      />
    </section>
  );
};
