import { UnitSeconds } from './CreateProspecting.types';

export const convertDelayTimeToMiliseconds = (time: string) => {
  const splittedTimeToArray = time.split(' ');
  let total = 0;

  splittedTimeToArray.forEach((singleTime) => {
    const timeValue = Number(singleTime.slice(0, -1));
    const letter = singleTime[singleTime.length - 1];
    const letterInSeconds = UnitSeconds[(letter as unknown) as number];
    const resultInSeconds = timeValue * Number(letterInSeconds);

    total += resultInSeconds;
  });

  if (isNaN(total)) return null;

  const totalInMilis = total * 1000;
  return totalInMilis;
};
