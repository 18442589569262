import { useEffect, useState } from 'react';
import { Input, Typography } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { AppMessages } from 'i18n/messages';
import { CreatorFormElement } from 'ui/creatorFormElement/CreatorFormElement';
import { useLocale } from 'hooks/useLocale/useLocale';
import styles from '../Cascade.module.scss';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';

import { SimpleFiltersContainer } from './simpleFilters/SimpleFiltersContainer';
import { Filter } from './simpleFilters/SimpleFilters.types';
import { FiltersProps } from './Filters.types';
import { useQueryString } from './hooks/useQueryString';
import { useToogleFilters } from './hooks/useToogleFilters';
import { useValidateQueryString } from './hooks/useValidateQueryString';

export const Filters = ({ cascadeIndex }: FiltersProps) => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const { formatMessage } = useLocale();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const isAdvancedFiltering = watch(`cascades[${cascadeIndex}].isAdvancedFiltering`);
  const filteringQuery = watch(`cascades[${cascadeIndex}].filter`) || '';

  const { validateQueryWithPetty } = useValidateQueryString();
  const convertFiltersToQueryString = useQueryString();

  const {
    toggleFilters,
    isQueryStringError,
    setIsQueryStringError,
    errorModalMessages,
    backToSimpleFilters,
  } = useToogleFilters(cascadeIndex, filters, setFilters, convertFiltersToQueryString);

  useEffect(() => {
    const { flattenedParseOutput } = validateQueryWithPetty(filteringQuery);

    setFilters(flattenedParseOutput);
  }, []);

  return (
    <>
      <CreatorFormElement
        title={formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.title'] })}
        titleExtra={
          <Typography.Text className={styles.underline} onClick={toggleFilters}>
            {isAdvancedFiltering
              ? formatMessage({ id: AppMessages['prospectingCreator.cascade.switchToSimple'] })
              : formatMessage({ id: AppMessages['prospectingCreator.cascade.switchToAdvanced'] })}
          </Typography.Text>
        }
        text={formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.text'] })}
        errorMessage={errors?.cascades?.[cascadeIndex]?.filter?.message}
        data-testid="vendorFilteringForm"
        formElement={
          isAdvancedFiltering ? (
            <Controller
              control={control}
              defaultValue=""
              name={`cascades[${cascadeIndex}].filter`}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  value={filteringQuery || ''}
                  autoSize={{ minRows: 8, maxRows: 10 }}
                  className={clsx(styles.textarea, {
                    [styles.error]: errors?.['cascades']?.[cascadeIndex]?.['filter']?.['message'],
                  })}
                />
              )}
            />
          ) : (
            <SimpleFiltersContainer
              filters={filters}
              setFilters={(filters) => {
                const queryString = convertFiltersToQueryString(filters);

                setFilters(filters);
                setValue(`cascades[${cascadeIndex}].filter`, queryString);
              }}
            />
          )
        }
      />

      <ConfirmModal
        isOpen={isQueryStringError}
        onCancel={() => setIsQueryStringError(false)}
        onSubmit={() => {
          setFilters([]);
          setIsQueryStringError(false);
          backToSimpleFilters();
          setValue(`cascades[${cascadeIndex}].filter`, '');
        }}
        title={errorModalMessages.title}
        text={errorModalMessages.text}
        submitButtonText={formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.modal.resetButton'] })}
        cancelButtonText={formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.modal.backButton'] })}
      />
    </>
  );
};
