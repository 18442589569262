import { BrowserRouter as Router } from 'react-router-dom';

import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { ErrorBoundary } from 'app/errorBoundary/ErrorBoundary';

import { AppProvidersProps } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <LocaleContextController>
      <ErrorBoundary>
        <Router>
          <AuthContextController>
            <ClientContextController>{children}</ClientContextController>
          </AuthContextController>
        </Router>
      </ErrorBoundary>
    </LocaleContextController>
  );
};
