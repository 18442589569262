import { Button, Modal } from 'antd';

import { ConfirmModalProps } from './ConfirmModal.types';
import styles from './ConfirmModal.module.scss';

export const ConfirmModal = ({
  title,
  isOpen,
  onCancel,
  onSubmit,
  text,
  highlightedText,
  submitButtonText,
  cancelButtonText,
  isLoadingSubmitButton,
}: ConfirmModalProps) => {
  return (
    <Modal
      data-testid="confirmModal"
      visible={isOpen}
      onCancel={onCancel}
      width={700}
      title={title}
      footer={
        <>
          {submitButtonText !== undefined && (
            <Button type="primary" onClick={onSubmit} loading={isLoadingSubmitButton} disabled={isLoadingSubmitButton}>
              {submitButtonText}
            </Button>
          )}

          <Button onClick={onCancel}>{cancelButtonText}</Button>
        </>
      }
    >
      {highlightedText && <span className={styles.highlightedElement}>{highlightedText}</span>}
      {text}
    </Modal>
  );
};
