import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, Input } from 'antd';

import { AppMessages } from 'i18n/messages';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';
import { CreatorFormElement } from 'ui/creatorFormElement/CreatorFormElement';
import { useLocale } from 'hooks/useLocale/useLocale';
import styles from '../Cascade.module.scss';

import { SimpleSortingContainer } from './simpleSorting/SimpleSortingContainer';
import { SortingProps } from './Sorting.types';
import { Sort } from './simpleSorting/SimpleSorting.types';
import { useQueryString } from './hooks/useQueryString';
import { useToggleSorting } from './hooks/useToggleSorting';
import { useValidateSortingQueryString } from './hooks/useValidateQueryString';

export const Sorting = ({ cascadeIndex }: SortingProps) => {
  const [sorting, setSorting] = useState<Sort[]>([]);
  const { formatMessage } = useLocale();

  const { control, setValue, watch } = useFormContext();
  const isAdvancedSorting = watch(`cascades[${cascadeIndex}].isAdvancedSorting`);
  const sortingQuery = watch(`cascades[${cascadeIndex}].sort`) || '';

  const convertSortingToQueryString = useQueryString();
  const validateQueryWithPetty = useValidateSortingQueryString();
  const {
    toggleSorting,
    isQueryStringError,
    setIsQueryStringError,
    errorModalMessages,
    backToSimpleSorting,
  } = useToggleSorting(cascadeIndex);

  useEffect(() => {
    const { flattenedParseOutput } = validateQueryWithPetty(sortingQuery);

    setSorting(flattenedParseOutput);
  }, []);

  return (
    <>
      <CreatorFormElement
        title={formatMessage({ id: AppMessages['prospectingCreator.cascade.sort.title'] })}
        titleExtra={
          <Typography.Text className={styles.underline} onClick={() => toggleSorting(sorting, setSorting)}>
            {isAdvancedSorting
              ? formatMessage({ id: AppMessages['prospectingCreator.cascade.switchToSimple'] })
              : formatMessage({ id: AppMessages['prospectingCreator.cascade.switchToAdvanced'] })}
          </Typography.Text>
        }
        text={formatMessage({ id: AppMessages['prospectingCreator.cascade.sort.text'] })}
        data-testid="vendorSortingForm"
        formElement={
          isAdvancedSorting ? (
            <Controller
              control={control}
              name={`cascades[${cascadeIndex}].sort`}
              defaultValue=""
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  value={sortingQuery || ''}
                  autoSize={{ minRows: 8, maxRows: 10 }}
                  className={styles.textarea}
                />
              )}
            />
          ) : (
            <SimpleSortingContainer
              addedSorting={sorting}
              setAddedSorting={(sorting) => {
                const queryString = convertSortingToQueryString(sorting);

                setSorting(sorting);
                setValue(`cascades[${cascadeIndex}].sort`, queryString);
              }}
            />
          )
        }
      />

      <ConfirmModal
        isOpen={isQueryStringError}
        onCancel={() => setIsQueryStringError(false)}
        onSubmit={() => {
          setSorting([]);
          setIsQueryStringError(false);
          backToSimpleSorting();
          setValue(`cascades[${cascadeIndex}].sort`, '');
        }}
        title={errorModalMessages.title}
        text={errorModalMessages.text}
        submitButtonText={formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.modal.resetButton'] })}
        cancelButtonText={formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.modal.backButton'] })}
      />
    </>
  );
};
