import { Col, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { WarningFilled } from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';

import { XtrfCard } from 'ui/xtrfCard/XtrfCard';
import { CascadeExecutionStatus } from 'ui/cascadeExecutionStatus/CascadeExecutionStatus';
import { VendorOffer } from 'api/jobProspecting/jobProspecting.types';
import { formatDate } from 'helpers/formatDate';
import { RankingStrategiesMessages, VendorOfferStatus } from 'core/global.enum';
import { StatusLabel } from 'ui/statusLabel/StatusLabel';
import { useLocale } from 'hooks/useLocale/useLocale';
import { NO_VALUE_SYMBOL } from 'core/global.constants';
import { AppMessages } from 'i18n/messages';

import { AssignVendorContainer } from './assignVendor/AssignVendorContainer';
import { CascadeCardProps } from './CascadeCard.types';
import styles from './CascadeCard.module.scss';

export type ColumnData = {
  vendorOffer: VendorOffer;
};

export const CascadeCard = ({
  cascadeNumber,
  cascade,
  prospecting,
  areVisibleAssignVendorButtons,
  jobNumber,
}: CascadeCardProps) => {
  const { formatMessage } = useLocale();

  const validStatusesToShowData = [
    VendorOfferStatus.accepted,
    VendorOfferStatus.negotiated,
    VendorOfferStatus.awaitingTMSResponse,
    VendorOfferStatus.vendorAssignedTMSConfirmed,
  ];

  const shouldDisplayAssignVendorButtonsColumn = (vendorOffer: VendorOffer): boolean => {
    return (
      (validStatusesToShowData.includes(vendorOffer.status) && areVisibleAssignVendorButtons) ||
      !!prospecting.assignedVendorId
    );
  };

  const sortVendorOffersWithNegativePositionAtTheEnd = () => {
    const withPositivePosition = cascade.vendorOffers.filter((offer) => offer.position >= 0);
    const withNegativePosition = cascade.vendorOffers.filter((offer) => offer.position < 0);

    return [...withPositivePosition.sort((a, b) => a.position - b.position), ...withNegativePosition];
  };

  const displayCorrectPosition = (vendorOffer: VendorOffer) => {
    if (vendorOffer.exchangeRateFeeError) {
      return (
        <div className={styles.warningIconWrapper}>
          ?
          <Tooltip
            placement="rightTop"
            title={formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.warning'] })}
          >
            <WarningFilled className={styles.warningIcon} />
          </Tooltip>
        </div>
      );
    } else if (validStatusesToShowData.includes(vendorOffer.status)) {
      return `#${vendorOffer.rank}`;
    } else {
      return '?';
    }
  };

  const columns: ColumnsType<VendorOffer> = [
    {
      title: formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.rank'] }),
      dataIndex: 'rank',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'cascadeVendorRank',
        },
        children: displayCorrectPosition(vendorOffer),
      }),
      width: 100,
    },
    {
      title: formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.vendor'] }),
      dataIndex: 'name',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'cascadeVendorName',
        },
        children: vendorOffer.name,
      }),
    },
    {
      title: formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.proposedFee'] }),
      dataIndex: 'calculatedFee',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'cascadeVendorFee',
        },
        children:
          vendorOffer.calculatedFee && validStatusesToShowData.includes(vendorOffer.status)
            ? `${vendorOffer.calculatedFee.toFixed(2)} ${prospecting.projectCurrency}`
            : NO_VALUE_SYMBOL,
        align: 'right',
      }),
    },
    {
      title: formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.proposedDeadline'] }),
      dataIndex: 'deadline',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'cascadeVendorDeadline',
        },
        children:
          vendorOffer.deadline && validStatusesToShowData.includes(vendorOffer.status)
            ? formatDate(vendorOffer.deadline)
            : NO_VALUE_SYMBOL,
      }),
    },
    {
      title: formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.responded'] }),
      dataIndex: 'respondedAt',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'cascadeVendorRespondedAt',
        },
        children: vendorOffer.respondedAt ? (
          <Tooltip placement="bottom" title={formatDate(vendorOffer.respondedAt)}>
            {formatDistanceToNow(new Date(vendorOffer.respondedAt))} ago
          </Tooltip>
        ) : (
          NO_VALUE_SYMBOL
        ),
      }),
    },
    {
      title: formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.vendorOffer.offerStatus'] }),
      dataIndex: 'status',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'cascadeVendorStatus',
        },
        children: <StatusLabel status={vendorOffer.status} />,
      }),
      width: 210,
    },
    {
      key: 'assignVendorButtons',
      render: (_, vendorOffer) => ({
        props: {
          'data-testid': 'assignVendorButtons',
        },
        children: shouldDisplayAssignVendorButtonsColumn(vendorOffer) && (
          <AssignVendorContainer
            vendorOffer={vendorOffer}
            jobNumber={jobNumber}
            prospecting={prospecting}
            assignedVendorId={prospecting.assignedVendorId}
            status={vendorOffer.status}
          />
        ),
      }),
      width: 200,
    },
  ];

  return (
    <>
      <Col span={24} key={cascadeNumber} data-testid="cascadeCard">
        <XtrfCard
          title={`Cascade ${cascadeNumber}: ${formatMessage({
            id: RankingStrategiesMessages[cascade.rankingStrategy],
          })}`}
          extra={<CascadeExecutionStatus cascadeStatus={cascade.status} executionTime={cascade.sentAt} />}
        >
          <Table
            pagination={false}
            columns={columns.filter((column) =>
              prospecting.assignedVendorId || areVisibleAssignVendorButtons
                ? column
                : column.key !== 'assignVendorButtons',
            )}
            dataSource={sortVendorOffersWithNegativePositionAtTheEnd()}
            size="small"
            onRow={(_, index) => ({
              property: undefined,
              'data-testid': `cascadeVendorOffersRowIndex${index}`,
            })}
            rowKey="id"
            scroll={{ y: 400 }}
          />
        </XtrfCard>
      </Col>
    </>
  );
};
