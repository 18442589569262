import { useCallback, useMemo } from 'react';
import Axios from 'axios';
import { QueryClient, QueryClientProvider, QueryFunction } from 'react-query';

import { ClientContext } from '../clientContext/ClientContext';
import { ClientResponse } from 'core/global.types';
import { useAuth } from 'hooks/useAuth/useAuth';
import { AppRoute } from 'routing/AppRoute.enum';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';

import { requestSuccessInterceptor } from './interceptors/requestInterceptors';
import { responseFailureInterceptor, responseSuccessInterceptor } from './interceptors/responseInterceptors';
import { ClientProviderProps } from './ClientContextController.types';

export const ClientContextController = ({ children }: ClientProviderProps) => {
  const history = useCustomHistory();
  const { workspaceId, idToken } = useAuth();

  const axios = useMemo(() => {
    const axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
    });

    axios.interceptors.request.use((config) => requestSuccessInterceptor(config, workspaceId));
    axios.interceptors.response.use(responseSuccessInterceptor, (error) => {
      if (error.response) {
        if ([404, 403, 401].includes(error.response.status)) {
          history.push({
            route: AppRoute.notFound,
          });
        }
      }

      return responseFailureInterceptor(error);
    });

    return axios;
  }, [idToken]);

  const queryFn: QueryFunction<ClientResponse> = useCallback(
    async ({ queryKey: [url] }) => {
      if (typeof url === 'string') {
        const lowerCaseUrl = url.toLowerCase();
        return await axios.get<ClientResponse>(lowerCaseUrl);
      }
      throw new Error('Invalid QueryKey');
    },
    [axios],
  );

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          queryFn,
          retry: false,
          refetchOnWindowFocus: false,
          keepPreviousData: true,
          enabled: !!idToken,
        },
      },
    });
  }, [queryFn, idToken]);

  return (
    <ClientContext.Provider value={axios}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ClientContext.Provider>
  );
};
