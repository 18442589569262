import { Space, Input, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import {
  FilteringFunctionsMessages,
  FilterTypes,
  FilteringOptionsMessages,
  FilteringItemsMessages,
  SimpleFiltersProps,
  FilterValueTypes,
} from './SimpleFilters.types';
import styles from './SimpleFilters.module.scss';
import { FILTERING_FUNCTIONS, FILTERING_ITEMS } from './SimpleFilters.utils';

export const SimpleFilters = ({
  filters,
  onAddFilter,
  onDeleteFilter,
  onChangeFilterType,
  onChangeFilterFunction,
  onChangeFilterValue,
}: SimpleFiltersProps) => {
  const { formatMessage } = useLocale();

  return (
    <Space direction="vertical" className={styles.container}>
      {filters.map((filterObject) => (
        <Space key={filterObject.id}>
          <Select
            className={styles.select}
            showSearch
            optionFilterProp="children"
            value={filterObject.type}
            onChange={(selected) => onChangeFilterType(selected, filterObject.id)}
          >
            {Object.values(FilterTypes).map((filter) => (
              <Select.Option key={filter} value={filter}>
                {formatMessage({ id: FilteringOptionsMessages[filter] })}
              </Select.Option>
            ))}
          </Select>
          <Select
            className={styles.select}
            value={filterObject.function}
            onChange={(selected) => onChangeFilterFunction(selected, filterObject.id)}
          >
            {Object.values(FILTERING_FUNCTIONS[filterObject.type]).map((filter) => (
              <Select.Option key={filter} value={filter}>
                {formatMessage({
                  id: FilteringFunctionsMessages[filter as keyof typeof FilteringFunctionsMessages],
                })}
              </Select.Option>
            ))}
          </Select>
          {filterObject.valueType === FilterValueTypes.select ? (
            <Select
              className={styles.lastSelect}
              value={filterObject.value}
              onChange={(selected) => onChangeFilterValue(selected, filterObject.id)}
            >
              {Object.values(FILTERING_ITEMS[filterObject.type]).map((filter) => (
                <Select.Option key={filter} value={filter}>
                  {formatMessage({
                    id: FilteringItemsMessages[filter as keyof typeof FilteringItemsMessages],
                  })}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              className={styles.input}
              value={filterObject.value}
              type={filterObject.valueType === FilterValueTypes.number ? filterObject.valueType : 'text'}
              onChange={(e) => {
                if (filterObject.valueType === FilterValueTypes.number) {
                  if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0)
                    onChangeFilterValue(e.target.value, filterObject.id);
                } else {
                  onChangeFilterValue(e.target.value, filterObject.id);
                }
              }}
              {...(filterObject.valueType === FilterValueTypes.number && { min: 0 })}
              {...(filterObject.valueType === FilterValueTypes.number && { max: 100 })}
            />
          )}
          <CloseOutlined onClick={() => onDeleteFilter(filterObject.id)} />
        </Space>
      ))}
      <Select
        showSearch
        className={styles.addFilterSelect}
        placeholder={formatMessage({ id: AppMessages['prospectingCreator.simpleFilters.addFilter'] })}
        optionFilterProp="children"
        onChange={(filter: FilterTypes) => onAddFilter(filter)}
      >
        {Object.values(FilterTypes).map((filter) => (
          <Select.Option key={filter} value={filter}>
            {formatMessage({ id: FilteringOptionsMessages[filter] })}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};
