import { Form, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { getMonth, getYear, setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import { Input } from 'semantic-ui-react';
import { CalendarOutlined, ClockCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import 'react-datepicker/dist/react-datepicker.css';

import { XtrfCard } from 'ui/xtrfCard/XtrfCard';

import { ProspectingExpiryTimeCardProps } from './ProspectingExpiryTimeCard.types';
import styles from './ProspectingExpiryTimeCard.module.scss';
import { useFormatDate } from './hooks/useFormatDate';
import { formatDateWithTimeZone, months, years } from './ProspectingExpiryTimeCard.utils';

export const ProspectingExpiryTimeCard = ({
  startDate,
  endDate,
  onDateChange,
  onTimeChange,
  dateSelected,
  timeSelected,
  minTime,
  setDeadlineDate,
  isInputDateError,
  setIsInputDateError,
  isInputTimeError,
}: ProspectingExpiryTimeCardProps) => {
  const { watch } = useFormContext();
  const expiryAt = watch('expiryAt');

  const formatteStartDate = useFormatDate(startDate, expiryAt);
  const formattedEndDate = useFormatDate(endDate, expiryAt);
  const splittedDate =
    expiryAt !== undefined
      ? formatDateWithTimeZone(expiryAt.toISOString(), {
          dateStyle: 'full',
        }).split(' ')
      : '';

  return (
    <XtrfCard
      title={<FormattedMessage id="createProspecting.prospectingExpiryTimeCard.title" />}
      data-testid="prospectingExpiryTimeCard"
    >
      <Form.Item
        className={styles.calendar}
        label={<FormattedMessage id="createProspecting.prospectingExpiryTimeCard.label" />}
      >
        <div className={styles.timezone}>
          <FormattedMessage id="createProspecting.prospectingExpiryTimeCard.timezone" />{' '}
          <span>{splittedDate[splittedDate.length - 1]}</span>
        </div>

        <div className={styles.dateTimePickerWrapper}>
          <DatePicker
            onChange={onDateChange}
            wrapperClassName={styles.datePickerWrapper}
            popperClassName={styles.datePopper}
            calendarClassName={styles.calendar}
            dayClassName={() => styles.day}
            className={styles.datePicker}
            selected={dateSelected}
            dateFormat="dd-MM-yyyy"
            showPopperArrow={false}
            popperPlacement="top"
            placeholderText="dd-MM-yyyy"
            minDate={new Date(minTime)}
            onChangeRaw={(event) => {
              const isDateFromCalendar = event.target.value === undefined;
              if (isDateFromCalendar) return;

              const inputDateParts = event.target.value.split('-');
              const inputDateStringInDDMMYYY = `${inputDateParts[1]}-${inputDateParts[0]}-${inputDateParts[2]}`;
              const isDateValid = !isNaN(Date.parse(inputDateStringInDDMMYYY));

              if (isDateValid) {
                const newInputRawDate = new Date(inputDateStringInDDMMYYY);
                const timeHours = timeSelected.getHours();
                const timeMinutes = timeSelected.getMinutes();
                const timeSeconds = 0;

                newInputRawDate.setHours(timeHours, timeMinutes, timeSeconds);
                setDeadlineDate(newInputRawDate);
                setIsInputDateError(true);
              }
            }}
            customInput={
              <Input
                className={`${styles.input} ${isInputDateError ? styles.errorInput : ''}`}
                icon={<CalendarOutlined className={`${styles.icon} ${styles.calendarIcon}`} />}
              />
            }
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
            }) => (
              <div className={styles.datepickerHeader}>
                <LeftOutlined onClick={decreaseMonth} className={styles.arrowIcon} disabled={prevMonthButtonDisabled} />
                <div>
                  <select
                    className={styles.select}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    className={styles.select}
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(Number(value))}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                <RightOutlined onClick={increaseMonth} className={styles.arrowIcon} />
              </div>
            )}
          />

          <div>
            <DatePicker
              onChange={onTimeChange}
              wrapperClassName={styles.datePickerWrapper}
              timeClassName={() => styles.time}
              className={styles.timePicker}
              selected={timeSelected}
              placeholderText="hh:mm"
              minTime={
                dateSelected.toISOString().split('T')[0] === new Date(minTime).toISOString().split('T')[0]
                  ? setHours(setMinutes(new Date(), new Date(minTime).getMinutes()), new Date(minTime).getHours())
                  : setHours(setMinutes(new Date(), 0), 0)
              }
              maxTime={setHours(setMinutes(new Date(), 59), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              showPopperArrow={false}
              popperPlacement="top"
              popperClassName={styles.timePopper}
              customInput={
                <Input
                  className={`${styles.input} ${isInputTimeError ? styles.errorInput : ''}`}
                  icon={<ClockCircleOutlined className={styles.icon} />}
                />
              }
            />
          </div>
        </div>

        {(isInputDateError || isInputTimeError) && (
          <div className={styles.inputErrorMessage}>
            <FormattedMessage id="createProspecting.prospectingExpiryTimeCard.currentDateError" />
          </div>
        )}
      </Form.Item>

      <div className={styles.expiryAdditionalInfo}>
        {formatteStartDate !== '' && (
          <Typography.Text type="secondary" className={styles.text}>
            {formatteStartDate} <FormattedMessage id="createProspecting.prospectingExpiryTimeCard.beforeStart" />
          </Typography.Text>
        )}

        {formattedEndDate !== '' && (
          <Typography.Text type="secondary">
            {formattedEndDate} <FormattedMessage id="createProspecting.prospectingExpiryTimeCard.beforeDeadline" />
          </Typography.Text>
        )}
      </div>
    </XtrfCard>
  );
};
