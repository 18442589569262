import { minutesToHours, secondsToMinutes, daysToWeeks, millisecondsToSeconds } from 'date-fns';
import parseToMilliseconds from 'parse-duration';

export const addAfterfix = (time: number, afterfix: string): string =>
  time !== undefined && time > 0 ? `${time}${afterfix}` : '';

const toTimeDuration = (milliseconds: number): string => {
  const seconds = millisecondsToSeconds(milliseconds);
  const minutes = secondsToMinutes(seconds);
  const hours = minutesToHours(minutes);
  const days = Math.floor(hours / 24);
  const weeks = daysToWeeks(days);

  const minutesLeft = minutes - 60 * hours;
  const hoursLeft = hours - days * 24;
  const daysLeft = days - weeks * 7;

  const filterOutEmpty = [
    addAfterfix(minutesLeft, 'm'),
    addAfterfix(hoursLeft, 'h'),
    addAfterfix(daysLeft, 'd'),
    addAfterfix(weeks, 'w'),
  ]
    .filter((format) => !!format)
    .sort((a, b) => {
      const [indexOfA, indexOfB] = [a, b].map((text) =>
        ['w', 'd', 'h', 'm'].findIndex((value) => text.includes(value)),
      );
      return indexOfA - indexOfB;
    });

  return filterOutEmpty.join(' ');
};

const fromTimeDuration = (duration: string): number => {
  return parseToMilliseconds(duration, 'ms');
};

export const parseTime = {
  toTimeDuration,
  fromTimeDuration,
};
