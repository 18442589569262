import { Space, Select, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import {
  SortingOptions,
  SimpleSortingProps,
  SortingOptionsMessages,
  SortingFunctionsMessages,
} from './SimpleSorting.types';
import { SORTING_FUNCTIONS } from './SimpleSortingContainer';
import styles from './SimpleSorting.module.scss';

export const SimpleSorting = ({
  addedSorting,
  onAddSorting,
  onDeleteSorting,
  onChangeMainSorting,
  onChangeFunction,
}: SimpleSortingProps) => {
  const { formatMessage } = useLocale();

  return (
    <Space direction="vertical" className={styles.container}>
      {addedSorting.map((addedSortObject, index) => (
        <Space key={addedSortObject.id}>
          <Typography.Text className={styles.sortingText}>
            {index === 0
              ? formatMessage({ id: AppMessages['prospectingCreator.simpleSorting.sortBy'] })
              : formatMessage({ id: AppMessages['prospectingCreator.simpleSorting.then'] })}
          </Typography.Text>
          <Select
            className={styles.select}
            showSearch
            optionFilterProp="children"
            value={addedSortObject.option}
            onChange={(selected) => onChangeMainSorting(selected, addedSortObject.id)}
          >
            {Object.values(SortingOptions).map((sortBy) => (
              <Select.Option key={sortBy} value={sortBy}>
                {formatMessage({ id: SortingOptionsMessages[sortBy] })}
              </Select.Option>
            ))}
          </Select>
          <Select
            className={styles.lastSelect}
            value={addedSortObject.function}
            onChange={(selected) => onChangeFunction(selected, addedSortObject.id)}
          >
            {Object.values(SORTING_FUNCTIONS[addedSortObject.option]).map((sortBy) => (
              <Select.Option key={sortBy} value={sortBy}>
                {formatMessage({
                  id: SortingFunctionsMessages[sortBy as keyof typeof SortingFunctionsMessages],
                })}
              </Select.Option>
            ))}
          </Select>
          <CloseOutlined onClick={() => onDeleteSorting(addedSortObject.id)} />
        </Space>
      ))}
      <Select
        showSearch
        className={styles.addSortingSelect}
        placeholder={formatMessage({ id: AppMessages['prospectingCreator.simpleSorting.addSorting'] })}
        optionFilterProp="children"
        onChange={(sortBy: SortingOptions) => onAddSorting(sortBy)}
      >
        {Object.values(SortingOptions).map((filter) => (
          <Select.Option key={filter} value={filter}>
            {formatMessage({ id: SortingOptionsMessages[filter] })}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};
