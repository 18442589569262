import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { filterParse } from '@xtrf/petty';

import {
  FlattenedFilter,
  ParseExpression,
  ParseOutput,
  ParseProperties,
  QueryStringProperties,
} from '../Filters.types';
import {
  DateFunctions,
  DateItems,
  Filter,
  FilterFunctions,
  FilterTypes,
  FilterValueTypes,
} from '../simpleFilters/SimpleFilters.types';
import { FILTERING_ITEMS } from '../simpleFilters/SimpleFilters.utils';

const ADDITIONAL_PROPERTY_NAME = 'ADDITIONAL_PROPERTY' as ParseProperties;

export const useValidateQueryString = () => {
  const flatParseObject = (property: ParseProperties, operator: FilterFunctions, value: string | string[]) => {
    const isValueAnArray = typeof value === 'object';
    const parseProperty = QueryStringProperties[property];
    const isPropertyStringType = FILTERING_ITEMS[parseProperty] === FilterValueTypes.string;
    const isPropertyNumberType = FILTERING_ITEMS[parseProperty] === FilterValueTypes.number;
    const isAdditionalProperty = property === ADDITIONAL_PROPERTY_NAME;

    return {
      id: uuidv4(),
      type: isAdditionalProperty
        ? ((ADDITIONAL_PROPERTY_NAME as unknown) as FilterTypes)
        : ((QueryStringProperties[property] as unknown) as FilterTypes),
      function: operator,
      originalValue: value,
      value: isValueAnArray ? value.join() : value.toString(),
      valueType: isPropertyStringType
        ? FilterValueTypes.string
        : isPropertyNumberType
        ? FilterValueTypes.number
        : FilterValueTypes.select,
    };
  };

  const flattenParseOutput = (
    expressionObject: ParseOutput | ParseExpression | null,
    flattenedParseOutput: Filter[],
  ) => {
    if (expressionObject === null) return [];

    const { left, right, operator, property, value } = expressionObject;

    if (property && value !== undefined) {
      const flattenedParseObject = flatParseObject(property, operator, value);
      flattenedParseOutput.push(flattenedParseObject);

      return;
    }

    if (left && right) {
      const { operator, property, value } = left;

      if (property && value !== undefined) {
        const flattenedParseObject = flatParseObject(property, operator, value);
        flattenedParseOutput.push(flattenedParseObject);
      }

      flattenParseOutput(right, flattenedParseOutput);
    }
  };

  const validateQueryWithPetty = useCallback((queryString: string) => {
    const flattenedParseOutput: Filter[] = [];
    let isValid;
    let errorMessage = '';

    try {
      const parseOutput = queryString ? (filterParse(queryString) as ParseOutput) : null;
      flattenParseOutput(parseOutput, flattenedParseOutput);

      isValid = true;
    } catch (error) {
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      isValid = false;
    }

    return { isValid, flattenedParseOutput, errorMessage };
  }, []);

  const validateIfQueryHasCustomDate = useCallback((flattenedParseOutput: Filter[]) => {
    const possibleDateFunctions = Object.values(DateFunctions) as string[];
    const dateFilters = flattenedParseOutput.filter(({ function: filterFunction }: Filter) =>
      possibleDateFunctions.includes(filterFunction),
    );

    let customDate;
    const possibleDates = Object.values(DateItems) as string[];
    const isCustomDateInQuery = dateFilters.some(({ originalValue }: FlattenedFilter) => {
      const isCustomDate = possibleDates.includes(originalValue as string) === false;

      if (isCustomDate) {
        customDate = originalValue;
        return true;
      }

      return false;
    });

    return { isCustomDate: isCustomDateInQuery, customDate };
  }, []);

  const validateIfQueryHasAdditionalProperty = useCallback((flattenedParseOutput: Filter[]) => {
    const isAdditionalProperty = flattenedParseOutput.some(({ type }: Filter) =>
      type.includes(ADDITIONAL_PROPERTY_NAME),
    );

    return isAdditionalProperty;
  }, []);

  return {
    validateQueryWithPetty,
    validateIfQueryHasCustomDate,
    validateIfQueryHasAdditionalProperty,
  };
};
