import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProspectingData } from 'api/jobProspecting/jobProspecting';
import { QueryKey, VendorOfferStatus } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { changeAppTitle } from 'helpers/changeAppTitle';
import { AppRoute } from 'routing/AppRoute.enum';

import { JobProspectingStatusPage } from './JobProspectingStatusPage';

export const JobProspectingStatusPageContainer = () => {
  const { id } = useParams<{ id: string }>();
  const [areVisibleAssignVendorButtons, setAreVisibleAssignVendorButtons] = useState(false);

  const { data: prospectingDataResponse, isLoading, isFetchedAfterMount } = useQuery(
    QueryKey.PROSPECTING_DATA,
    () => getProspectingData(id),
    {
      refetchInterval: 6000,
    },
  );

  const isVendorOfferWithStatusAwaiting = prospectingDataResponse?.data.prospecting.cascades.some((cascade) =>
    cascade.vendorOffers.some((offer) => offer.status === VendorOfferStatus.awaitingTMSResponse),
  );

  useEffect(() => {
    setAreVisibleAssignVendorButtons(isVendorOfferWithStatusAwaiting || false);
  }, [isVendorOfferWithStatusAwaiting]);

  useEffect(() => {
    prospectingDataResponse?.data.prospecting.job.data.number &&
      changeAppTitle({
        onRoute: AppRoute.prospecting,
        title: `Prospecting ${prospectingDataResponse?.data.prospecting.job.data.number}`,
      });
  }, [prospectingDataResponse]);

  return (
    <JobProspectingStatusPage
      prospectingData={prospectingDataResponse?.data}
      isLoading={isLoading || !isFetchedAfterMount}
      areVisibleAssignVendorButtons={areVisibleAssignVendorButtons}
      setAreVisibleAssignVendorButtons={setAreVisibleAssignVendorButtons}
    />
  );
};
