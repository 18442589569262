import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';
import clsx from 'clsx';

import { CascadeStatus } from 'core/global.enum';
import { formatDate } from 'helpers/formatDate';

import styles from './CascadeExecutionStatus.module.scss';
import { CascadeExecutionStatusProps } from './CascadeExecutionStatus.types';

export const CascadeExecutionStatus = ({ cascadeStatus, executionTime }: CascadeExecutionStatusProps) => {
  if (cascadeStatus === CascadeStatus.abandon) {
    return (
      <Space className={styles.label}>
        <FormattedMessage id="prospectingDetails.cascadeCard.executionStatus.notSent" />
      </Space>
    );
  }

  if (cascadeStatus === CascadeStatus.sent || cascadeStatus === CascadeStatus.finished) {
    return (
      <Space className={clsx(styles.label, styles.sent)}>
        <CheckOutlined className={styles.icon} />
        <FormattedMessage
          id="prospectingDetails.cascadeCard.executionStatus.sentOnLabel"
          values={{ dateTime: formatDate(executionTime) }}
        />
      </Space>
    );
  } else {
    return (
      <Space>
        <ClockCircleOutlined className={styles.icon} />
        <FormattedMessage
          id="prospectingDetails.cascadeCard.executionStatus.toBeSentLabel"
          values={{ dateTime: formatDate(executionTime) }}
        />
      </Space>
    );
  }
};
