import { AxiosRequestConfig } from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';

export const requestSuccessInterceptor = async (
  config: AxiosRequestConfig,
  workspaceId: string | null,
): Promise<AxiosRequestConfig> => {
  const { currentUser } = getAuth();

  if (currentUser) {
    const token = await getIdToken(currentUser);

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        'x-workspace-id': workspaceId,
      },
      withCredentials: true,
    };
  }

  return config;
};
