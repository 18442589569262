import { getProspectingStrategies } from 'api/prospectingStrategies/prospectingStrategies';
import { QueryKey } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';

import { ProspectingStrategiesList } from './ProspectingStrategiesList';

export const ProspectingStrategiesListContainer = () => {
  const { data: prospectingStrategiesList, isLoading } = useQuery(
    QueryKey.PROSPECTING_STRATEGIES,
    getProspectingStrategies,
  );

  return <ProspectingStrategiesList prospectingStrategiesList={prospectingStrategiesList} isLoading={isLoading} />;
};
