import { v4 as uuidv4 } from 'uuid';

import {
  Filter,
  FilterFunctions,
  FilterTypes,
  FilterValues,
  FilterValueTypes,
  UseFiltersMethodsParams,
} from '../simpleFilters/SimpleFilters.types';
import { FILTERING_FUNCTIONS, FILTERING_ITEMS } from '../simpleFilters/SimpleFilters.utils';

export const useFiltersMethods = ({ filters, setFilters }: UseFiltersMethodsParams) => {
  const addFilter = (filter: FilterTypes) => {
    setFilters([
      ...filters,
      {
        id: uuidv4(),
        type: filter,
        function: Object.values(FILTERING_FUNCTIONS[filter])[0],
        value:
          FILTERING_ITEMS[filter] === FilterValueTypes.string
            ? ('' as FilterValues)
            : FILTERING_ITEMS[filter] === FilterValueTypes.number
            ? ('0' as FilterValues)
            : Object.values(FILTERING_ITEMS[filter])[0],
        valueType:
          FILTERING_ITEMS[filter] === FilterValueTypes.string
            ? FilterValueTypes.string
            : FILTERING_ITEMS[filter] === FilterValueTypes.number
            ? FilterValueTypes.number
            : FilterValueTypes.select,
      },
    ]);
  };

  const deleteFilter = (filterId: string) => setFilters(filters.filter((filter) => filter.id !== filterId));

  const changeFilterType = (selected: FilterTypes, filterId: Filter['id']) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              type: selected,
              function: Object.values(FILTERING_FUNCTIONS[selected])[0],
              value:
                FILTERING_ITEMS[selected] === FilterValueTypes.string
                  ? ('' as FilterValues)
                  : FILTERING_ITEMS[selected] === FilterValueTypes.number
                  ? ('0' as FilterValues)
                  : Object.values(FILTERING_ITEMS[selected])[0],
              valueType:
                FILTERING_ITEMS[selected] === FilterValueTypes.string
                  ? FilterValueTypes.string
                  : FILTERING_ITEMS[selected] === FilterValueTypes.number
                  ? FilterValueTypes.number
                  : FilterValueTypes.select,
            }
          : filter,
      ),
    );
  };

  const changeFilterFunction = (selected: FilterFunctions, filterId: Filter['id']) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              function: selected,
            }
          : filter,
      ),
    );
  };

  const changeFilterValue = (value: FilterValues, filterId: Filter['id']) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? {
              ...filter,
              value,
            }
          : filter,
      ),
    );
  };

  return { addFilter, deleteFilter, changeFilterType, changeFilterFunction, changeFilterValue };
};
