import { Typography, Space } from 'antd';

import { CreatorFormElementProps } from './CreatorFormElement.types';
import styles from './CreatorFormElement.module.scss';

export const CreatorFormElement = ({
  title,
  titleExtra,
  text,
  formElement,
  note,
  errorMessage,
  'data-testid': dataTestId,
}: CreatorFormElementProps) => {
  return (
    <Space direction="vertical" className={styles.formElementWrapper} data-testid={dataTestId}>
      <Space>
        <Typography.Text strong>{title}</Typography.Text>
        {titleExtra}
      </Space>

      <Typography.Text type="secondary">{text}</Typography.Text>

      {formElement}

      {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}

      <Space>{note}</Space>
    </Space>
  );
};
