import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { cancelProspecting } from 'api/jobProspecting/jobProspecting';
import { useMutation } from 'hooks/useMutation/useMutation';
import { QueryKey } from 'core/global.enum';

import { CancelProspecting } from './CancelProspecting';
import { CancelProspectingContainerProps } from './CancelProspecting.types';

export const CancelProspectingContainer = ({ prospectingId, disabled }: CancelProspectingContainerProps) => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: onCancelProspecting, isLoading } = useMutation(QueryKey.CANCEL_PROSPECTING, cancelProspecting, {
    onSuccess: () => {
      queryClient.refetchQueries(QueryKey.PROSPECTING_DATA);
      setIsModalOpen(false);
    },
  });

  const onSubmit = () => {
    onCancelProspecting(prospectingId);
  };

  return (
    <CancelProspecting
      isModalOpen={isModalOpen}
      onCloseModal={() => setIsModalOpen(false)}
      onOpenModal={() => setIsModalOpen(true)}
      onSubmit={onSubmit}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
};
