import { useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Form, Radio, Select, Space } from 'antd';
import { FormattedMessage } from 'react-intl';

import { XtrfCard } from 'ui/xtrfCard/XtrfCard';

import { ProspectingSettingsCardProps } from './ProspectingSettingsCard.types';

export const ProspectingSettingsCard = ({
  prospecting,
  prospectingStrategyShortList,
}: ProspectingSettingsCardProps) => {
  const { setValue, control } = useFormContext();

  const prospectingStrategyId = useWatch({
    control,
    name: 'prospectingStrategy',
    defaultValue: prospecting?.prospectingStrategy?.id,
  });

  const strategy = prospectingStrategyShortList?.find(({ id }) => prospectingStrategyId === id);

  useEffect(() => {
    if (!strategy) return;

    setValue('prospectingStrategy', strategy.id);
    setValue('automaticallyAssignVendor', strategy.automaticallyAssignVendor);
  }, [strategy, setValue]);

  return (
    <XtrfCard
      title={<FormattedMessage id="createProspecting.settingsCard.title" />}
      data-testid="prospectingSettingsCard"
    >
      <Form.Item
        style={{ width: '550px' }}
        label={<FormattedMessage id="createProspecting.prospectingStrategy" />}
        {...(strategy?.description && { help: strategy?.description })}
        data-testid="prospectingSettings"
      >
        <Controller
          control={control}
          name="prospectingStrategy"
          defaultValue=""
          render={({ field }) => (
            <Select {...field} showSearch optionFilterProp="children">
              {prospectingStrategyShortList?.map((strategy) => (
                <Select.Option key={strategy.id} value={strategy.id}>
                  {strategy.name}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>

      <Form.Item label={<FormattedMessage id="createProspecting.vendorAssigment" />} data-testid="vendorAssignment">
        <Controller
          control={control}
          name="automaticallyAssignVendor"
          defaultValue={strategy?.automaticallyAssignVendor}
          render={({ field }) => (
            <Radio.Group {...field} size="large">
              <Space direction="vertical" data-testid="vendorAssignmentOptions">
                <Radio value={true} data-testid="automaticallyAssignmentRadioButton">
                  <FormattedMessage id="createProspecting.vendorAssigment.automatically" />
                </Radio>
                <Radio value={false} data-testid="manuallyAssignmentRadioButton">
                  <FormattedMessage id="createProspecting.vendorAssigment.manually" />
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Form.Item>
    </XtrfCard>
  );
};
