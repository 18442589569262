import { Checkbox, Input, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ClockCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { intervalToDuration } from 'date-fns';

import { XtrfCard } from 'ui/xtrfCard/XtrfCard';
import { VendorOffer } from 'api/jobProspecting/jobProspecting.types';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import {
  NegotiationStrategiesDescription,
  NegotiationStrategiesMessages,
  RankingStrategiesDescription,
  RankingStrategiesMessages,
} from 'core/global.enum';
import { parseTime } from 'helpers/parseTime';
import { CreatorFormElement } from 'ui/creatorFormElement/CreatorFormElement';

import { CascadeSettingsCardProps } from './CascadeSettingsCard.types';
import styles from './CascadeSettingsCard.module.scss';

export const CascadeSettingsCard = ({ cascade, index, lastCascade, sentAfter }: CascadeSettingsCardProps) => {
  const { formatMessage } = useLocale();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { hours, minutes } = intervalToDuration({ start: 0, end: sentAfter ?? 0 });

  const formattedHours = hours ? `${hours}h` : '';
  const formattedMinutes = minutes ? ` ${minutes}m` : '';
  const formattedSentAfterTime = formattedHours + formattedMinutes;

  const columns: ColumnsType<VendorOffer> = [
    {
      className: 'xtrfTableColumn',
      title: <FormattedMessage id="createProspecting.cascadeSettingsCard.recipients" />,
      dataIndex: 'name',
    },
  ];

  if (!cascade || !cascade?.vendorOffers.length) {
    return (
      <XtrfCard
        data-testid="cascadeSettingsCardNoResults"
        title={
          <Space>
            <div>{`Cascade ${index + 1}`}</div>
            <div className={styles.recipientsInfo}>
              {formatMessage({
                id: AppMessages['createProspecting.noRecipients'],
              })}
            </div>
          </Space>
        }
      >
        <div className={styles.noResults}>
          {formatMessage({
            id: AppMessages['createProspecting.noResults'],
          })}
        </div>
        <Controller control={control} name={`cascades[${index}].lifespan`} defaultValue={'0m'} render={() => <></>} />
      </XtrfCard>
    );
  }

  return (
    <XtrfCard
      data-testid="cascadeSettingsCard"
      title={
        <Space className={styles.cascadeHeader}>
          <div className={styles.cascadeHeaderLeftPart}>
            <div data-testid="cascadeName">{`Cascade ${index + 1}`} </div>
            <div
              className={styles.recipientsInfo}
              data-testid="recipients"
            >{`${cascade.vendorOffers.length} recipients`}</div>
          </div>
          {index !== 0 && sentAfter !== undefined && formattedSentAfterTime && (
            <div className={styles.sentAfterWrapper}>
              <ClockCircleOutlined />
              <div className={styles.sentAfter}>
                {`${formatMessage({
                  id: AppMessages['createProspecting.sentAfter'],
                })} ${formattedSentAfterTime}`}
              </div>
            </div>
          )}
        </Space>
      }
    >
      <Table
        pagination={false}
        columns={columns}
        dataSource={cascade?.vendorOffers}
        rowKey="vendorId"
        className={styles.recipientsTable}
        scroll={{ y: 400, x: 0 }}
        data-testid="recipientsTable"
      />
      <Space direction="vertical" className="ant-form-item" data-testid="negotiationStrategy">
        <Space>
          <Typography.Text strong>
            <FormattedMessage id="createProspecting.cascadeSettingsCard.negotiationStrategy" />
          </Typography.Text>

          <FormattedMessage id={AppMessages[NegotiationStrategiesMessages[cascade.negotiationStrategy]]} />
          <Tooltip title={<FormattedMessage id="createProspecting.cascadeSettingsCard.negotiationStrategy.tooltip" />}>
            <QuestionCircleFilled className={styles.fieldExplanation} />
          </Tooltip>
        </Space>
        <Typography.Text type="secondary">
          <FormattedMessage id={AppMessages[NegotiationStrategiesDescription[cascade.negotiationStrategy]]} />
        </Typography.Text>
      </Space>

      <Space direction="vertical" className="ant-form-item" data-testid="rankingStrategy">
        <Space>
          <Typography.Text strong>
            <FormattedMessage id="createProspecting.cascadeSettingsCard.contractProposalRankingStrategy" />
          </Typography.Text>
          <FormattedMessage id={AppMessages[RankingStrategiesMessages[cascade.rankingStrategy]]} />
          <Tooltip
            title={
              <FormattedMessage id="createProspecting.cascadeSettingsCard.contractProposalRankingStrategy.tooltip" />
            }
          >
            <QuestionCircleFilled className={styles.fieldExplanation} />
          </Tooltip>
        </Space>
        <Typography.Text type="secondary">
          <FormattedMessage id={AppMessages[RankingStrategiesDescription[cascade.rankingStrategy]]} />
        </Typography.Text>
      </Space>

      {!lastCascade && (
        <>
          <CreatorFormElement
            title={formatMessage({
              id: AppMessages['createProspecting.cascadeSettingsCard.timeDelayBeforeStartingNextCascade'],
            })}
            text={formatMessage({
              id: AppMessages['createProspecting.cascadeSettingsCard.timeDelayBeforeStartingNextCascade.helper'],
            })}
            errorMessage={errors?.['cascades']?.[index]?.['lifespan']?.['message']}
            data-testid="timeDelayForm"
            formElement={
              <Space>
                <Controller
                  control={control}
                  name={`cascades[${index}].lifespan`}
                  defaultValue={parseTime.toTimeDuration(cascade?.lifespan)}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={formatMessage({
                        id: AppMessages['prospectingCreator.cascade.timeDelay.placeholder'],
                      })}
                      className={clsx({
                        [styles.errored]: errors?.['cascades']?.[index]?.['lifespan']?.['message'],
                      })}
                    />
                  )}
                />
                <Typography.Text>
                  {formatMessage({ id: AppMessages['prospectingCreator.cascade.timeDelay.note'] })}
                </Typography.Text>
              </Space>
            }
            note={
              <Controller
                control={control}
                name={`cascades[${index}].immortal`}
                defaultValue={cascade?.immortal}
                render={({ field }) => (
                  <Checkbox checked={field.value} onChange={(e) => field.onChange(e.target.checked)}>
                    {formatMessage({
                      id: AppMessages['createProspecting.cascadeSettingsCard.allowRespondContinuously'],
                    })}
                  </Checkbox>
                )}
              />
            }
          />
        </>
      )}
    </XtrfCard>
  );
};
