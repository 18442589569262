import { EnvelopeIcon } from 'assets/icons/EnvelopeIcon';
import { UserIcon } from 'assets/icons/UserIcon';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import styles from './SendingJobOffersLoader.module.scss';

export const SendingJobOffersLoader = () => {
  const { formatMessage } = useLocale();

  const showIconsOnCircle = () => {
    const angles = [15, 89, 159, 231, 303];

    return angles.map((a) => (
      <UserIcon
        key={a}
        width={40}
        height={40}
        style={{
          transform: `rotate(${a}deg) translate(100px) rotate(-${a}deg)`,
          position: 'absolute',
        }}
      />
    ));
  };

  return (
    <>
      <div className={styles.container} data-testid="sendingJobOffersLoader">
        <span className={styles.animatedBackground}></span>

        {showIconsOnCircle()}

        <EnvelopeIcon width={50} height={50} />
        {/* 
        <svg
          version="1.2"
          preserveAspectRatio="none"
          viewBox="0 0 24 24"
          className="ng-element"
          data-id="b7a727351b7f497e90135183666b054e"
          style={{
            opacity: 1,
            fill: '#0424D5',
            width: '50px',
            height: '50px',
            position: 'absolute',
          }}
        >
          <g>
            <path
              d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
              style={{ fill: '#0424D5' }}
            />
          </g>
        </svg> */}
      </div>
      <div className={styles.sendingOffersInfo}>
        {formatMessage({ id: AppMessages['createProspecting.sendingOffers'] })}
      </div>
    </>
  );
};
