import { sortParse } from '@xtrf/petty';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  ParseExpression,
  ParseOutput,
  ParseProperties,
  ParserPropertyToFunctionTranslation,
  QueryStringProperties,
  Sort,
  SortingFunctions,
  SortingOperators,
  SortingOptions,
} from '../simpleSorting/SimpleSorting.types';

export const useValidateSortingQueryString = () => {
  const flatParseObject = (property: ParseProperties, operator: SortingOperators) => {
    return {
      id: uuidv4(),
      option: (QueryStringProperties[property] as unknown) as SortingOptions,
      function: (ParserPropertyToFunctionTranslation[`${property}_${operator}`] as unknown) as SortingFunctions,
    };
  };

  const flattenParseOutput = (expressionObject: ParseOutput | ParseExpression | null, flattenedParseOutput: Sort[]) => {
    if (expressionObject === null) return [];

    const { left, right, operator, property } = expressionObject;

    if (property !== undefined) {
      const flattenedParseObject = flatParseObject(property, operator);
      flattenedParseOutput.push(flattenedParseObject);

      return;
    }

    if (left && right) {
      const { operator, property } = left;

      if (property !== undefined) {
        const flattenedParseObject = flatParseObject(property, operator);
        flattenedParseOutput.push(flattenedParseObject);
      }

      flattenParseOutput(right, flattenedParseOutput);
    }
  };

  const validateQueryWithPetty = useCallback((queryString: string) => {
    const flattenedParseOutput: Sort[] = [];
    let isValid;
    let errorMessage = '';

    try {
      const parseOutput = queryString ? (sortParse(queryString) as ParseOutput) : null;
      flattenParseOutput(parseOutput, flattenedParseOutput);

      isValid = true;
    } catch (error) {
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      isValid = false;
    }

    return { isValid, flattenedParseOutput, errorMessage };
  }, []);

  return validateQueryWithPetty;
};
