import { LeftOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip, Typography } from 'antd';

import { UserIcon } from 'assets/icons/UserIcon';
import { ProspectingStatus, VendorOfferStatus } from 'core/global.enum';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { Header } from 'ui/header/Header';
import { CancelProspectingContainer } from '../cancelProspecting/CancelProspectingContainer';

import { JobProspectingStatusPageHeaderProps } from './JobProspectingStatusPageHeader.types';
import styles from './JobProspectingStatusPageHeader.module.scss';

export const JobProspectingStatusPageHeader = ({
  prospectingData,
  setAreVisibleAssignVendorButtons,
  areVisibleAssignVendorButtons,
  assignedVendor,
}: JobProspectingStatusPageHeaderProps) => {
  const { formatMessage } = useLocale();
  const history = useCustomHistory();

  const isSomeVendorOfferAccepted = prospectingData?.prospecting?.cascades.some(({ vendorOffers }) =>
    vendorOffers.some(
      (vendorOffer) =>
        vendorOffer.status === VendorOfferStatus.accepted ||
        vendorOffer.status === VendorOfferStatus.negotiated ||
        vendorOffer.status === VendorOfferStatus.vendorAssignedTMSConfirmed,
    ),
  );

  const shouldDisplayAssignVendorButton =
    prospectingData &&
    prospectingData.prospecting.status !== ProspectingStatus.created &&
    prospectingData.prospecting.status !== ProspectingStatus.cancelled &&
    !prospectingData.prospecting.assignedVendorId;

  const disableCancelProspectingButton =
    prospectingData &&
    [
      ProspectingStatus.noRecommendation,
      ProspectingStatus.noProposalReceived,
      ProspectingStatus.recommendedVendorSelected,
      ProspectingStatus.otherVendorSelected,
      ProspectingStatus.cancelled,
      ProspectingStatus.prepared,
      ProspectingStatus.created,
      ProspectingStatus.vendorFound,
    ].includes(prospectingData.prospecting.status);

  const renderHeaderBody = () => {
    if (shouldDisplayAssignVendorButton) {
      return (
        <Space>
          <Tooltip
            title={
              !isSomeVendorOfferAccepted
                ? formatMessage({
                    id: AppMessages['prospectingDetails.actions.selectVendorNow.disabledText'],
                  })
                : ''
            }
          >
            <Button
              onClick={() => setAreVisibleAssignVendorButtons(!areVisibleAssignVendorButtons)}
              disabled={!isSomeVendorOfferAccepted}
              className={styles.selectVendorButton}
              data-testid="selectVendorButton"
            >
              {formatMessage({
                id: AppMessages['prospectingDetails.actions.selectVendorNow'],
              })}
            </Button>
          </Tooltip>

          <CancelProspectingContainer
            prospectingId={prospectingData.prospecting.id}
            disabled={disableCancelProspectingButton}
          />
        </Space>
      );
    } else if (
      prospectingData?.prospecting.status !== ProspectingStatus.cancelled &&
      prospectingData?.prospecting.status !== ProspectingStatus.inProgress
    ) {
      return (
        <div className={styles.assignedLabel} data-testid="assignedVendorLabel">
          <div className={styles.userIconWrapper}>
            <UserIcon width={35} height={35} />
          </div>

          <div>
            <Typography.Text className={styles.assignedLabelText}>
              {formatMessage({ id: AppMessages['prospectingDetails.cascadeCard.assignedVendor.label'] })}
            </Typography.Text>
            <Typography.Text className={styles.assignedLabelUserName}>{assignedVendor?.name}</Typography.Text>
          </div>
        </div>
      );
    }
  };

  return (
    <Header
      title={formatMessage(
        { id: AppMessages['prospectingDetails.title'] },
        { prospecting: `${prospectingData?.prospecting.job.data.number}` },
      )}
      status={prospectingData?.prospecting.status}
      beforeTitleAddon={
        <Button
          onClick={() =>
            history.push({
              route: AppRoute.jobProspectingDashboard,
            })
          }
          className={styles.backToListButton}
          icon={<LeftOutlined />}
          data-testid="backToListButton"
        >
          {formatMessage({
            id: AppMessages['prospectingDetails.backButton'],
          })}
        </Button>
      }
      data-testid="prospectingDetailsTitle"
    >
      {renderHeaderBody()}
    </Header>
  );
};
