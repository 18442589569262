import { useEffect, useState } from 'react';

import { getProspectingList } from 'api/jobProspecting/jobProspecting';
import { ProspectingListQuery, ProspectingListQuerySort } from 'api/jobProspecting/jobProspecting.types';
import { useQuery } from 'hooks/useQuery/useQuery';
import { QueryKey } from 'core/global.enum';

import { JobProspectingDashboard } from './JobProspectingDashboard';

export const JobProspectingDashboardContainer = () => {
  const [page, setPage] = useState(1);
  const [perPage] = useState(50);
  const [query, setQuery] = useState<ProspectingListQuery>({
    jobNumbers: [],
    status: [],
    sort: undefined,
  });

  const { data: prospectingListResponse, isLoading } = useQuery(
    [QueryKey.PROSPECTING_LIST, page, perPage, query],
    getProspectingList,
    {
      args: {
        oneOf: {
          jobNumber: query.jobNumbers,
          status: query.status,
        },
        take: perPage,
        skip: (page - 1) * perPage,
        sort: query.sort,
      },
    },
  );

  useEffect(() => {
    setPage(1);
  }, [query]);

  const toggleSortQuery = (sortValueAsc: ProspectingListQuerySort, sortValueDesc: ProspectingListQuerySort) => {
    if (!query.sort) {
      setQuery({ ...query, sort: sortValueAsc });
    } else if (query.sort === sortValueAsc) {
      setQuery({ ...query, sort: sortValueDesc });
    } else {
      setQuery({ ...query, sort: sortValueAsc });
    }
  };

  return (
    <JobProspectingDashboard
      prospectingListResponse={prospectingListResponse}
      isLoading={isLoading}
      query={query}
      setQuery={setQuery}
      page={page}
      setPage={setPage}
      perPage={perPage}
      toggleSortQuery={toggleSortQuery}
    />
  );
};
