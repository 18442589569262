import { Button } from 'antd';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';

import { CancelProspectingProps } from './CancelProspecting.types';

export const CancelProspecting = ({
  isModalOpen,
  onCloseModal,
  onOpenModal,
  isLoading,
  onSubmit,
  disabled,
}: CancelProspectingProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Button onClick={onOpenModal} data-testid="cancelProspectingButton" disabled={disabled}>
        {formatMessage({
          id: AppMessages['prospectingDetails.actions.cancelProspecting'],
        })}
      </Button>

      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={onCloseModal}
        onSubmit={onSubmit}
        text={formatMessage({
          id: AppMessages['prospectingDetails.cascadeCard.cancelProspectingConfirmation.title'],
        })}
        submitButtonText={formatMessage({
          id: AppMessages['prospectingDetails.cascadeCard.cancelProspectingConfirmation.okText'],
        })}
        cancelButtonText={formatMessage({
          id: AppMessages['prospectingDetails.cascadeCard.cancelProspectingConfirmation.cancelText'],
        })}
        isLoadingSubmitButton={isLoading}
      />
    </>
  );
};
