import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Header } from 'ui/header/Header';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { XtrfCard } from 'ui/xtrfCard/XtrfCard';
import { AppRoute } from 'routing/AppRoute.enum';
import { useCustomHistory } from 'hooks/useCustomHistory/useCustomHistory';
import styles from '../prospectingStrategyCreator/ProspectingStrategyCreator.module.scss';

import { ProspectingStrategiesListProps } from './ProspectingStrategiesList.types';

export const ProspectingStrategiesList = ({ prospectingStrategiesList, isLoading }: ProspectingStrategiesListProps) => {
  const { formatMessage } = useLocale();
  const history = useCustomHistory();

  const columns: ColumnsType<{ id: string; name: string }> = [
    {
      title: 'Rule Name',
      dataIndex: 'ruleName',
      className: 'xtrfTableColumn clickable',
      render: (_, data) => {
        return <div data-testid={data.name}>{data.name}</div>;
      },
    },
  ];

  return (
    <div className={styles.container}>
      <Header
        title={formatMessage({ id: AppMessages['prospectingStrategies.title'] })}
        data-testid="prospectingStrategiesTitle"
      >
        <Button
          onClick={() => {
            history.push({
              route: AppRoute.prospectingStrategyCreator,
            });
          }}
        >
          {formatMessage({
            id: AppMessages['prospectingStrategies.addStrategy'],
          })}
        </Button>
      </Header>
      <XtrfCard loading={isLoading}>
        <Table
          pagination={false}
          columns={columns}
          dataSource={prospectingStrategiesList}
          size="small"
          rowKey="id"
          onRow={(record) => ({
            onClick: () =>
              history.push({
                route: AppRoute.prospectingStrategyCreator,
                id: record.id,
              }),
          })}
        />
      </XtrfCard>
    </div>
  );
};
