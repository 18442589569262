import * as yup from 'yup';

import { useValidateTimeDuration } from 'hooks/useValidateTimeDuration/useValidateTimeDuration';

export const useCreateProspectingValidation = () => {
  const { validateTimeDuration } = useValidateTimeDuration();

  const schema = yup.object().shape({
    cascades: yup.array().of(
      yup.object().shape({
        lifespan: validateTimeDuration(),
      }),
    ),
  });

  return { schema };
};
