import { Button, Typography, Tooltip } from 'antd';
import { CheckCircleFilled, InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { VendorOfferStatus } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';

import { AssignVendorProps } from './AssignVendor.types';
import styles from './AssignVendor.module.scss';

export const AssignVendor = ({
  vendorOffer,
  assignVendorStatus,
  assignedVendorId,
  jobNumber,
  handleAssigning,
  isModalOpen,
  onModalOpen,
  onModalClose,
  selectedVendorOffer,
  isTMSReponseError,
}: AssignVendorProps) => {
  const { formatMessage } = useLocale();

  const isVendorAssigned = vendorOffer.vendorId === assignedVendorId;
  const hasCorrectStatusToDisplayReassignButton = [
    VendorOfferStatus.accepted,
    VendorOfferStatus.negotiated,
    VendorOfferStatus.vendorAssignedTMSConfirmed,
  ].includes(vendorOffer.status);

  if (assignVendorStatus === VendorOfferStatus.awaitingTMSResponse) {
    return isTMSReponseError ? (
      <Tooltip
        title={formatMessage({
          id: AppMessages['prospectingDetails.cascadeCard.assignVendor.tmsNoResponse'],
        })}
      >
        <div className={styles.tmsNoResponseMessageWrapper}>
          <ExclamationCircleOutlined className={styles.notSentIcon} />
          <Typography.Text ellipsis className={styles.messageText}>
            {formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.assignVendor.tmsNoResponse'],
            })}
          </Typography.Text>
        </div>
      </Tooltip>
    ) : (
      <Button
        className={styles.assignVendorButton}
        danger={vendorOffer.rank === 1 && vendorOffer.isRecommended}
        type={vendorOffer.rank === 1 && vendorOffer.isRecommended ? 'primary' : 'default'}
        loading={true}
        disabled
      >
        {formatMessage({
          id: AppMessages['prospectingDetails.cascadeCard.assigningVendor.button'],
        })}
      </Button>
    );
  }

  if (!!assignedVendorId) {
    if (isVendorAssigned) {
      return (
        <div className={styles.messageWrapper}>
          <CheckCircleFilled className={styles.successIcon} />
          <Typography.Text ellipsis className={clsx(styles.messageText, styles.successMessage)}>
            {formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.assignVendor.accepted'],
            })}
          </Typography.Text>
        </div>
      );
    } else if (hasCorrectStatusToDisplayReassignButton) {
      return (
        <>
          <Button
            danger={vendorOffer.rank === 1 && vendorOffer.isRecommended}
            type={vendorOffer.rank === 1 && vendorOffer.isRecommended ? 'primary' : 'default'}
            onClick={onModalOpen}
            className={styles.assignVendorButton}
          >
            {formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.reassignVendor.button'],
            })}
          </Button>

          <ConfirmModal
            isOpen={isModalOpen}
            onCancel={onModalClose}
            onSubmit={() => {
              selectedVendorOffer && handleAssigning(selectedVendorOffer.vendorId, 'reassign');
              onModalClose();
            }}
            text={formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.reassignVendor.modalText'],
            })}
            submitButtonText={formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.reassignVendor.modalOkText'],
            })}
            cancelButtonText={formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.reassignVendor.modalCancelText'],
            })}
          />
        </>
      );
    } else {
      return null;
    }
  } else {
    if (assignVendorStatus === VendorOfferStatus.noResponse) {
      return (
        <Tooltip
          title={formatMessage({
            id: AppMessages['prospectingDetails.cascadeCard.assignVendor.notSent'],
          })}
        >
          <div className={styles.messageWrapper}>
            <InfoCircleOutlined className={styles.notSentIcon} />
            <Typography.Text ellipsis className={styles.messageText}>
              {formatMessage({
                id: AppMessages['prospectingDetails.cascadeCard.assignVendor.notSent'],
              })}
            </Typography.Text>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <>
          <Button
            className={styles.assignVendorButton}
            danger={vendorOffer.rank === 1 && vendorOffer.isRecommended}
            type={vendorOffer.rank === 1 && vendorOffer.isRecommended ? 'primary' : 'default'}
            onClick={onModalOpen}
          >
            {formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.assignVendor.button'],
            })}
          </Button>

          <ConfirmModal
            isOpen={isModalOpen}
            onCancel={onModalClose}
            onSubmit={() => {
              selectedVendorOffer && handleAssigning(selectedVendorOffer.vendorId, 'assign');
              onModalClose();
            }}
            text={formatMessage(
              {
                id: 'prospectingDetails.cascadeCard.assignVendorConfirmation.title',
              },
              {
                vendor: <b>{selectedVendorOffer?.name}</b>,
                job: <b>{jobNumber}</b>,
              },
            )}
            submitButtonText={formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.assignVendorConfirmation.okText'],
            })}
            cancelButtonText={formatMessage({
              id: AppMessages['prospectingDetails.cascadeCard.assignVendorConfirmation.cancelText'],
            })}
          />
        </>
      );
    }
  }
};
