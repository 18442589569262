import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { SortColumnHeaderProps } from './SortColumnHeader.types';
import styles from './SortColumnHeader.module.scss';

export const SortColumnHeader = ({ title, onClick, isSorted, isSortActive }: SortColumnHeaderProps) => {
  return (
    <div className={styles.columnHeaderWrapper} onClick={onClick} data-testid="sortColumnHeader">
      {title}
      {isSorted ? (
        <ArrowDownOutlined
          className={clsx(styles.sortArrow, {
            [styles.arrowVisible]: isSortActive,
          })}
          data-testid="arrowDownIcon"
        />
      ) : (
        <ArrowUpOutlined
          className={clsx(styles.sortArrow, {
            [styles.arrowVisible]: isSortActive,
          })}
          data-testid="arrowUpIcon"
        />
      )}
    </div>
  );
};
