import { useCallback } from 'react';
import { QueryFunction, QueryKey, useQuery as useRQQuery, UseQueryResult } from 'react-query';
import { stringify } from 'qs';

import { useClient } from 'hooks/useClient/useClient';
import { useError } from 'hooks/useError/useError';

import { UseQueryFn, UseQueryOptions } from './useQuery.types';

export const useQuery = <TArgs = unknown, TParams = unknown, TError = unknown, TResponse = TParams>(
  queryKey: QueryKey,
  query: UseQueryFn<TArgs, TParams, TResponse>,
  options?: UseQueryOptions<TArgs, TParams, TError, TResponse>,
): UseQueryResult<TResponse, TError> => {
  const { handleError } = useError();
  const client = useClient();

  const { endpoint } = query(options?.args);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const queryFn: QueryFunction<TParams> = useCallback(() => {
    const queryParams = options?.args ? `?${stringify(options?.args)}` : '';

    return client.get(`${endpoint}${queryParams}`);
  }, [client, endpoint, options]);

  const errorFn = (e: TError) => {
    handleError(e);

    if (!options?.onError) return;

    options?.onError(e);
  };

  return useRQQuery<TParams, TError, TResponse, QueryKey>(queryKey, queryFn, { ...options, onError: errorFn });
};
