import { StopOutlined } from '@ant-design/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Header } from 'ui/header/Header';
import { AppMessages } from 'i18n/messages';

import { ErrorPageProps } from './ErrorPage.types';
import styles from './ErrorPage.module.scss';

export const ErrorPage = ({ type }: ErrorPageProps) => {
  const { formatMessage } = useLocale();

  const text = {
    notFound: formatMessage({ id: AppMessages['error.notFound.text'] }),
    noAccess: formatMessage({ id: AppMessages['error.noAccess.text'] }),
  }[type];

  const code = {
    notFound: formatMessage({ id: AppMessages['error.notFound.code'] }),
    noAccess: formatMessage({ id: AppMessages['error.noAccess.code'] }),
  }[type];

  return (
    <div className={styles.container}>
      <Header title={formatMessage({ id: AppMessages['errorPage.title'] })} />

      <div className={styles.errorWrapper}>
        <StopOutlined className={styles.icon} />

        <p className={styles.text}>{text}</p>

        <p className={styles.errorCode}>{code}</p>
      </div>
    </div>
  );
};
