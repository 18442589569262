import * as yup from 'yup';

import { parseTime } from 'helpers/parseTime';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

export const useValidateTimeDuration = () => {
  const { formatMessage } = useLocale();

  const validateTimeDuration = () => {
    return yup
      .string()
      .required(formatMessage({ id: AppMessages['common.requiredField'] }))
      .matches(
        /^(?!.*([wdhm]).*\1)\d*\.?\d+[wdhml](?: \d*\.?\d+[wdhm])*$/,
        formatMessage({ id: AppMessages['common.timeDuration.validation.wrongFormat'] }),
      )
      .test(
        'timeDuration',
        formatMessage({ id: AppMessages['common.timeDuration.validation.wrongFormat'] }),
        (fieldValue: string | undefined) => {
          if (!fieldValue) return true;

          return true;
        },
      )
      .test(
        'timeDuration',
        formatMessage({
          id: AppMessages['common.timeDuration.validation.tooLongTimeDuration'],
        }),
        (fieldValue: string | undefined) => {
          if (!fieldValue) return true;
          const maxTimeDelay = 2147400000;

          const milliseconds = parseTime.fromTimeDuration(fieldValue);

          if (milliseconds >= maxTimeDelay) return false;
          return true;
        },
      );
  };

  return { validateTimeDuration };
};
