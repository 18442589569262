import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { scroller, animateScroll } from 'react-scroll';

import { Cascade } from './Cascade';
import { CascadeContainerProps } from './Cascade.types';

export const CascadeContainer = ({
  cascade,
  index,
  removeCascade,
  duplicateCascade,
  isLastCasdade,
  cascadeFormId,
  numberOfCascades,
}: CascadeContainerProps) => {
  const {
    formState: { errors },
    setValue,
    watch,
    setFocus,
  } = useFormContext();

  const additionalLifespanValue = watch('additionalLifespan');
  useEffect(() => {
    if (isLastCasdade) {
      setValue(`cascades[${index}].lifespan`, additionalLifespanValue);
    }
  }, [setValue, index, additionalLifespanValue, isLastCasdade]);

  const scrollToFirstError = useCallback(() => {
    const DELAY_SCROLL_MILISECONDS = 500;
    const basicScrollOptions = { duration: 500, smooth: true };

    const isErrorInGeneralSettings = Object.keys(errors).some((error) => error !== 'cascades');

    if (isErrorInGeneralSettings) {
      const fieldName = Object.keys(errors)[0];

      setTimeout(() => {
        animateScroll.scrollToTop(basicScrollOptions);
        setFocus(fieldName);
      }, DELAY_SCROLL_MILISECONDS);
    }

    if (!isErrorInGeneralSettings && !!errors?.['cascades']?.[index]) {
      setValue(`cascades[${index}].isCollapsed`, true);

      const firstErrorIndex = Object.keys(errors.cascades)[0];

      const fieldName = Object.keys(errors?.cascades?.[firstErrorIndex]).sort((a, b) => (a < b ? 1 : -1))[0];

      setTimeout(() => {
        scroller.scrollTo(`cascades[${firstErrorIndex}].${fieldName}`, { ...basicScrollOptions, offset: -400 });
        setFocus(`cascades[${firstErrorIndex}].${fieldName}`);
      }, DELAY_SCROLL_MILISECONDS);
    }
  }, [errors, index, setFocus, setValue]);

  useEffect(() => {
    scrollToFirstError();
  }, [scrollToFirstError]);

  return (
    <Cascade
      cascade={cascade}
      index={index}
      removeCascade={removeCascade}
      duplicateCascade={duplicateCascade}
      isLastCasdade={isLastCasdade}
      cascadeFormId={cascadeFormId}
      numberOfCascades={numberOfCascades}
    />
  );
};
