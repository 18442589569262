import { useLocale } from 'hooks/useLocale/useLocale';
import { Filter, FilteringFunctionsMessages, FilterTypes } from '../simpleFilters/SimpleFilters.types';

export const useQueryString = () => {
  const { formatMessage } = useLocale();

  const convertFiltersToQueryString = (filters: Filter[]) => {
    return filters.reduce((prev: string, curr: Filter, index) => {
      const { type, function: operator, value } = curr;

      const queryOperator = formatMessage({
        id: FilteringFunctionsMessages[operator],
      });
      let queryValue;

      switch (type) {
        case FilterTypes.country:
          const valueQueryStringNotation = `"${value}"`;

          queryValue = valueQueryStringNotation;
          break;

        case FilterTypes.categories:
        case FilterTypes.tools:
          const valueQueryArrayNotation = `("${value.split(',').join('","')}")`;

          queryValue = valueQueryArrayNotation;
          break;

        default:
          queryValue = value;
          break;
      }

      const isFirstFilter = !index;
      const andOperatorNotation = 'AND';
      const firstFilterString = `${type} ${queryOperator} ${queryValue}`;
      const nextFiltersString = `${prev} ${andOperatorNotation} ${type} ${queryOperator} ${queryValue}`;
      const result = isFirstFilter ? firstFilterString : nextFiltersString;

      return result;
    }, '');
  };

  return convertFiltersToQueryString;
};
