import { StatusLabel } from '../statusLabel/StatusLabel';

import styles from './Header.module.scss';
import { HeaderProps } from './Header.types';

export const Header = ({
  title,
  status,
  children,
  renderCustomTitle,
  beforeTitleAddon,
  'data-testid': dataTestId,
}: HeaderProps) => {
  return (
    <div className={styles.container} data-testid={dataTestId}>
      {beforeTitleAddon}

      {renderCustomTitle ? (
        renderCustomTitle
      ) : (
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{title}</div>
        </div>
      )}

      {status && <StatusLabel status={status} />}

      <div className={styles.actions}>{children}</div>
    </div>
  );
};
