import { AxiosError } from 'axios';

enum NetworkError {
  message = 'Network Error',
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const isAxiosError = (e: any): e is AxiosError => {
  return typeof e.response == 'object';
};
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const isNetworkError = (e: any): e is NetworkError => {
  return typeof e.message == 'string' && e.message === NetworkError.message;
};

export const isServerError = (e: AxiosError) => {
  if (!e.response) {
    return false;
  }
  const status = e.response.status;
  return status >= 500;
};

export const guards = {
  isAxiosError,
  isNetworkError,
  isServerError,
};
