import { useState } from 'react';
import { Typography, Select, Tag, Checkbox, Input } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

import { FilterLanguages } from './filterLanguages/FilterLanguages';
import { FiltersProps } from './Filters.types';
import styles from './Filters.module.scss';

export const Filters = ({ prospectingListResponse, query, setQuery }: FiltersProps) => {
  const { formatMessage } = useLocale();
  const [searchJobNumberValue, setSearchJobNumberValue] = useState('');
  const [filterOptions] = useState(prospectingListResponse?.oneOf.jobs);

  return (
    <div className={styles.container} data-testid="filtersComponent">
      <Typography.Text>{formatMessage({ id: AppMessages['jobProspectingDashboard.filters.title'] })}</Typography.Text>

      <Select
        data-testid="jobFilterSelect"
        placeholder={formatMessage({ id: AppMessages['jobProspectingDashboard.filters.job.placeholder'] })}
        mode="multiple"
        maxTagCount="responsive"
        showArrow
        className={styles.select}
        searchValue=""
        onBlur={() => setSearchJobNumberValue('')}
        suffixIcon={<CaretDownOutlined className={styles.selectArrowIcon} />}
        dropdownRender={(menu) => (
          <div>
            <Input
              placeholder={formatMessage({
                id: AppMessages['jobProspectingDashboard.filters.job.search.placeholder'],
              })}
              className={styles.searchInput}
              onChange={(e) => setSearchJobNumberValue(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              value={searchJobNumberValue}
            />
            {menu}
          </div>
        )}
        onChange={(jobNumbers: string[]) => setQuery({ ...query, jobNumbers })}
        menuItemSelectedIcon={null}
        dropdownClassName={styles.dropdown}
        tagRender={({ value, closable, onClose }) => (
          <Tag
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            closable={closable}
            onClose={onClose}
            className={styles.tag}
          >
            {value}
          </Tag>
        )}
      >
        {filterOptions &&
          filterOptions
            .sort((a, b) => (a.number > b.number ? -1 : 1))
            .filter(({ number }) => number.toUpperCase().includes(searchJobNumberValue.toUpperCase()))
            .map(({ number, sourceLanguages, targetLanguages }) => (
              <Select.Option key={number} value={number} data-testid="jobFilterSelectOption">
                <div className={styles.optionBodyWrapper}>
                  <div>
                    <Checkbox checked={query.jobNumbers?.includes(number)} />
                    <Typography.Text className={styles.jobNumberText}>{number}</Typography.Text>
                  </div>

                  <FilterLanguages sourceLanguages={sourceLanguages} targetLanguages={targetLanguages} />
                </div>
              </Select.Option>
            ))}
      </Select>

      <Select
        data-testid="statusFilterSelect"
        placeholder={formatMessage({ id: AppMessages['jobProspectingDashboard.filters.status.placeholder'] })}
        mode="multiple"
        maxTagCount="responsive"
        showArrow
        className={styles.select}
        suffixIcon={<CaretDownOutlined className={styles.selectArrowIcon} />}
        onChange={(option: string[]) => setQuery({ ...query, status: option })}
        menuItemSelectedIcon={null}
        dropdownClassName={styles.dropdown}
        tagRender={({ value, closable, onClose }) => (
          <Tag
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            closable={closable}
            onClose={onClose}
            className={styles.tag}
          >
            {formatMessage({ id: `status.${value}` })}
          </Tag>
        )}
      >
        {prospectingListResponse?.oneOf.status.map((status) => (
          <Select.Option key={status} value={status}>
            <Checkbox checked={query.status?.includes(status)} />
            <Typography.Text className={styles.jobNumberText}>
              {formatMessage({ id: `status.${status}` }).toUpperCase()}
            </Typography.Text>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
