import { UseQueryFn } from 'hooks/useQuery/useQuery.types';
import { MutationFn } from 'hooks/useMutation/useMutation.types';

import {
  ProspectingStrategy,
  ProspectingStrategyAddConfigPayload,
  ProspectingStrategyEditConfigPayload,
  ProspectingStrategyShortList,
} from './prospectingStrategies.types';

export const getProspectingStrategies: UseQueryFn<unknown, ProspectingStrategy[]> = () => {
  return {
    endpoint: `/prospecting-strategy/`,
  };
};

export const addProspectingStrategy: MutationFn<ProspectingStrategyAddConfigPayload, ProspectingStrategy> = (body) => {
  return {
    endpoint: '/prospecting-strategy',
    method: 'POST',
    params: {
      ...body,
    },
  };
};

export const editProspectingStrategy: MutationFn<ProspectingStrategyEditConfigPayload, ProspectingStrategy> = ({
  id,
  body,
}) => {
  return {
    endpoint: `/prospecting-strategy/${id}`,
    method: 'PUT',
    params: {
      ...body,
    },
  };
};

export const removeProspectingStrategy: MutationFn<string, unknown> = (id) => {
  return {
    endpoint: `/prospecting-strategy/${id}`,
    method: 'DELETE',
  };
};

export const getProspectingStrategyShortList: UseQueryFn<unknown, ProspectingStrategyShortList> = () => {
  return {
    endpoint: `/prospecting-strategy/short-list`,
  };
};
