import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AppMessages } from 'i18n/messages';
import { message } from 'ui/message/message';
import { Filter } from '../simpleFilters/SimpleFilters.types';
import { useLocale } from 'hooks/useLocale/useLocale';

import { useValidateQueryString } from './useValidateQueryString';

export const useToogleFilters = (
  cascadeIndex: number,
  filters: Filter[],
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>,
  convertFiltersToQueryString: (filters: Filter[]) => string,
) => {
  const [isQueryStringError, setIsQueryStringError] = useState(false);
  const [errorModalMessages, setErrorModalMessages] = useState({ title: '', text: '' });

  const { formatMessage } = useLocale();
  const { setValue, watch } = useFormContext();
  const {
    validateQueryWithPetty,
    validateIfQueryHasCustomDate,
    validateIfQueryHasAdditionalProperty,
  } = useValidateQueryString();

  const isAdvancedFiltersOpen = watch(`cascades[${cascadeIndex}].isAdvancedFiltering`);
  const filteringQuery = watch(`cascades[${cascadeIndex}].filter`) || '';

  const openAdvancedFilters = () => setValue(`cascades[${cascadeIndex}].isAdvancedFiltering`, true);
  const backToSimpleFilters = () => setValue(`cascades[${cascadeIndex}].isAdvancedFiltering`, false);

  const toggleFilters = () => {
    if (!!isAdvancedFiltersOpen === false) {
      const queryString = convertFiltersToQueryString(filters);
      setValue(`cascades[${cascadeIndex}].filter`, queryString);

      openAdvancedFilters();
      return;
    }

    const { isValid: isPettyValid, flattenedParseOutput, errorMessage } = validateQueryWithPetty(filteringQuery);
    if (isPettyValid === false) {
      setIsQueryStringError(true);
      setErrorModalMessages({
        title: formatMessage({ id: AppMessages['prospectingCreator.cascade.filter.modal.title.petty'] }),
        text: errorMessage,
      });

      return;
    }

    const { isCustomDate, customDate } = validateIfQueryHasCustomDate(flattenedParseOutput);
    if (isCustomDate) {
      message.error(
        `"${customDate}" ${formatMessage({ id: AppMessages['prospectingCreator.toggleFilters.customDate'] })}`,
      );

      return;
    }

    const isAdditionalProperty = validateIfQueryHasAdditionalProperty(flattenedParseOutput);
    if (isAdditionalProperty) {
      message.error(formatMessage({ id: AppMessages['prospectingCreator.toggleFilters.additionalProperty'] }));

      return;
    }

    setFilters(flattenedParseOutput);
    backToSimpleFilters();
  };
  return { toggleFilters, isQueryStringError, setIsQueryStringError, errorModalMessages, backToSimpleFilters };
};
