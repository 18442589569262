import { getTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ProspectingExpiryTimeCard } from './ProspectingExpiryTimeCard';
import { ProspectingExpiryTimeCardContainerProps } from './ProspectingExpiryTimeCard.types';

export const ProspectingExpiryTimeCardContainer = ({
  jobDetails,
  prospecting,
  isInputDateError,
  setIsInputDateError,
  isInputTimeError,
  setIsInputTimeError,
  expiryTimeBasedOnCascadesDelay,
}: ProspectingExpiryTimeCardContainerProps) => {
  const { setValue, watch, trigger } = useFormContext();
  const defaultDateAndTime = new Date(getTime(new Date()) + (prospecting ? prospecting.additionalLifespan : 0));
  const expiryAt = watch('expiryAt');
  const mintimeDate = new Date();
  mintimeDate.setMinutes(mintimeDate.getMinutes() + 10);
  const mintime = mintimeDate.toISOString();
  const cascadesFormValues = watch('cascades');

  const [deadlineDate, setDeadlineDate] = useState<Date>(defaultDateAndTime);
  const [deadlineTime, setDeadlineTime] = useState<Date>(defaultDateAndTime);

  const changeDeadlineDate = (value: Date) => {
    if (value) {
      setDeadlineDate(value);
      setIsInputDateError(false);

      const isTimeBeforeMinTime = value.getTime() < new Date(mintime).getTime() - 60000;
      if (isTimeBeforeMinTime) {
        setIsInputTimeError(true);

        return;
      }

      setIsInputTimeError(false);
      setValue('expiryAt', value);
    }
  };

  const changeDeadlineTime = (value: Date) => {
    if (value) {
      const timeHours = value.getHours();
      const timeMinutes = value.getMinutes();
      const timeSeconds = 0;

      setDeadlineTime(value);
      deadlineDate.setHours(timeHours, timeMinutes, timeSeconds);

      const isTimeBeforeMinTime = deadlineDate.getTime() < new Date(mintime).getTime() - 60000;
      if (isTimeBeforeMinTime) {
        setIsInputTimeError(true);

        return;
      }

      setIsInputTimeError(false);
      setValue('expiryAt', deadlineDate);
    }
  };

  useEffect(() => {
    trigger();
    setValue('expiryAt', defaultDateAndTime);
  }, []);

  useEffect(() => {
    if (cascadesFormValues !== undefined) {
      changeDeadlineDate(new Date(expiryTimeBasedOnCascadesDelay));
      changeDeadlineTime(new Date(expiryTimeBasedOnCascadesDelay));
      setValue('expiryAt', new Date(expiryTimeBasedOnCascadesDelay));
    }
  }, [expiryTimeBasedOnCascadesDelay]);

  useEffect(() => {
    const isTimeBeforeMinTime = deadlineDate.getTime() < new Date(mintime).getTime() - 60000;

    if (isTimeBeforeMinTime === false) setIsInputTimeError(false);
  }, [expiryAt]);

  return (
    <ProspectingExpiryTimeCard
      endDate={jobDetails?.dates?.plannedEndDate}
      startDate={jobDetails?.dates?.plannedStartDate}
      onDateChange={changeDeadlineDate}
      onTimeChange={changeDeadlineTime}
      dateSelected={deadlineDate}
      timeSelected={deadlineTime}
      setDeadlineDate={setDeadlineDate}
      minTime={mintime}
      isInputDateError={isInputDateError}
      setIsInputDateError={setIsInputDateError}
      isInputTimeError={isInputTimeError}
    />
  );
};
