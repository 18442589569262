import { Sort, SortingFunctionsOrder } from '../simpleSorting/SimpleSorting.types';

export const useQueryString = () => {
  const convertSortingToQueryString = (sorting: Sort[]) =>
    sorting.reduce((prev: string, curr: Sort, index) => {
      const { option, function: operator } = curr;

      const isFirstSorting = !index;
      const thenOperatorNotation = 'THEN';
      const sortingFunctionOrder = SortingFunctionsOrder[operator];
      const firstSortingString = `${option} ${sortingFunctionOrder}`;
      const nextSortingsString = `${prev} ${thenOperatorNotation} ${option} ${sortingFunctionOrder}`;
      const result = isFirstSorting ? firstSortingString : nextSortingsString;

      return result;
    }, '');

  return convertSortingToQueryString;
};
