import * as yup from 'yup';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useValidateTimeDuration } from 'hooks/useValidateTimeDuration/useValidateTimeDuration';

export const useProspectingStrategyCreatorValidation = () => {
  const { formatMessage } = useLocale();
  const { validateTimeDuration } = useValidateTimeDuration();

  const schema = yup.object().shape({
    name: yup.string().required(formatMessage({ id: AppMessages['common.requiredField'] })),
    description: yup.string().required(formatMessage({ id: AppMessages['common.requiredField'] })),
    additionalLifespan: validateTimeDuration(),
    cascades: yup.array().of(
      yup.object().shape({
        lifespan: validateTimeDuration(),
        maxVendor: yup
          .number()
          .typeError(formatMessage({ id: AppMessages['common.requiredField'] }))
          .required(formatMessage({ id: AppMessages['common.requiredField'] })),
      }),
    ),
  });

  return { schema };
};
