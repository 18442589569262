import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

import { AuthContext } from '../authContext/AuthContext';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const [isAuthenticated, setIsAuthencticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [idToken, setIdToken] = useState<string | null>(null);

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const accessTokenFromQuery = query.get('accessToken');

  const workspaceId = window.location.pathname.split('/')[1];
  const accessTokenFromStorage = sessionStorage.getItem('accessToken') || '';

  const auth = getAuth();

  const redirectToChat = () => {
    window.location.replace(
      `${process.env.REACT_APP_CHAT_URL}/authorize?workspaceId=${workspaceId}&redirect=${window.location.href}`,
    );
  };

  useEffect(() => {
    setIsAuthenticating(true);

    if (!accessTokenFromQuery && !accessTokenFromStorage) {
      redirectToChat();
      return;
    }

    signInWithCustomToken(auth, accessTokenFromQuery || accessTokenFromStorage)
      .then((data) => {
        data.user.getIdToken(true).then((idToken) => {
          setIdToken(idToken);
          setIsAuthencticated(true);
          setIsAuthenticating(false);
        });
      })
      .catch(() => {
        sessionStorage.removeItem('accessToken');
        setIsAuthencticated(false);
        setIsAuthenticating(false);
        redirectToChat();
      });

    if (!accessTokenFromQuery) return;

    sessionStorage.setItem('accessToken', accessTokenFromQuery);

    query.delete('accessToken');
    history.replace({
      search: query.toString(),
    });
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticating, isAuthenticated, idToken, workspaceId }}>
      {children}
    </AuthContext.Provider>
  );
};
