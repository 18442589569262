import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Typography, Space, Input, Select, Checkbox, Button } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import Collapse from '@kunukn/react-collapse';
import clsx from 'clsx';

import { useLocale } from 'hooks/useLocale/useLocale';
import { DraggableElement } from 'ui/draggableElement/DraggableElement';
import { AppMessages } from 'i18n/messages';
import { CreatorFormElement } from 'ui/creatorFormElement/CreatorFormElement';
import {
  NegotiationStrategies,
  NegotiationStrategiesDescription,
  NegotiationStrategiesMessages,
  RankingStrategies,
  RankingStrategiesDescription,
  RankingStrategiesMessages,
} from 'core/global.enum';

import { CascadeProps } from './Cascade.types';
import styles from './Cascade.module.scss';
import { Filters } from './filters/Filters';
import { Sorting } from './sorting/Sorting';

export const Cascade = ({
  cascade,
  index,
  removeCascade,
  duplicateCascade,
  isLastCasdade,
  cascadeFormId,
  numberOfCascades,
}: CascadeProps) => {
  const { formatMessage } = useLocale();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const isCollapsed = watch(`cascades[${index}].isCollapsed`);
  const rankingStrategy = useWatch({
    control,
    name: `cascades[${index}].rankingStrategy`,
    defaultValue: RankingStrategies.firstOneWin,
  });
  const negotiationStrategy = useWatch({
    control,
    name: `cascades[${index}].negotiationStrategy`,
    defaultValue: NegotiationStrategies.negotiation,
  });

  return (
    <Draggable draggableId={cascadeFormId} index={index}>
      {(provided) => (
        <div
          className={styles.container}
          {...provided.draggableProps}
          ref={provided.innerRef}
          data-testid={`cascadeIndex${index}`}
        >
          <div
            className={styles.titleWrapper}
            {...provided.dragHandleProps}
            onClick={() => setValue(`cascades[${index}].isCollapsed`, !isCollapsed)}
          >
            <Space>
              <div className={styles.draggableElement}>
                <DraggableElement />
              </div>

              <Controller
                control={control}
                name={`cascades[${index}].title`}
                defaultValue={
                  cascade.title ||
                  `${formatMessage({ id: AppMessages['prospectingCreator.cascade'] })} ${cascade?.cascadeNumber}`
                }
                render={({ field }) => (
                  <input
                    {...field}
                    className={styles.cascadeNameInput}
                    onClick={(e) => e.stopPropagation()}
                    data-testid="cascadeTitleInput"
                  />
                )}
              />
            </Space>

            <div data-testid="cascadeTopBar">
              <Typography.Text type="secondary" className={styles.topBarText}>
                {formatMessage({ id: AppMessages['prospectingCreator.cascadeTopBarText'] })}
              </Typography.Text>

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  duplicateCascade(index, watch(`cascades[${index}]`));
                }}
                className={styles.removeButton}
                data-testid="duplicateCascadeButton"
              >
                {formatMessage({ id: AppMessages['prospectingCreator.duplicateCascadeButton'] })}
              </Button>

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  removeCascade();
                }}
                className={styles.removeButton}
                disabled={numberOfCascades <= 1}
                data-testid="removeCascadeButton"
              >
                {formatMessage({ id: AppMessages['prospectingCreator.removeCascadeButton'] })}
              </Button>

              {isCollapsed ? (
                <UpOutlined className={styles.arrowIcon} />
              ) : (
                <DownOutlined className={styles.arrowIcon} />
              )}
            </div>
          </div>

          <Collapse isOpen={isCollapsed}>
            <div className={styles.cascadeBody}>
              {cascade.id && (
                <Controller
                  control={control}
                  name={`cascades[${index}].id`}
                  defaultValue={cascade.id || ''}
                  render={({ field }) => <Input {...field} type="hidden" data-testid="cascadeIdInput" />}
                />
              )}

              <Space direction="vertical" className={styles.formBody}>
                <Filters cascadeIndex={index} />
                <Sorting cascadeIndex={index} />

                <CreatorFormElement
                  title={formatMessage({ id: AppMessages['prospectingCreator.cascade.vendorsNumber.title'] })}
                  text={formatMessage({ id: AppMessages['prospectingCreator.cascade.vendorsNumber.text'] })}
                  errorMessage={errors?.['cascades']?.[index]?.['maxVendor']?.['message']}
                  data-testid="vendorsNumberForm"
                  formElement={
                    <Controller
                      control={control}
                      name={`cascades[${index}].maxVendor`}
                      render={({ field }) => (
                        <Space>
                          <Input
                            {...field}
                            onChange={(e) => field.onChange(parseInt(e.target.value, 10) || e.target.value)}
                            onKeyDown={(e) => (e.key === 'e' || e.key === '+' || e.key === '-') && e.preventDefault()}
                            placeholder={formatMessage({
                              id: AppMessages['prospectingCreator.cascade.vendorsNumber.placeholder'],
                            })}
                            type="number"
                            min={1}
                            onWheel={(e) => e.currentTarget.blur()}
                            className={clsx(styles.numberInput, {
                              [styles.error]: errors?.['cascades']?.[index]?.['maxVendor']?.['message'],
                            })}
                            data-testid="vendorsNumberInput"
                          />
                        </Space>
                      )}
                    />
                  }
                />

                <CreatorFormElement
                  title={formatMessage({ id: AppMessages['prospectingCreator.cascade.negotiationStrategy.title'] })}
                  text={formatMessage({ id: AppMessages['prospectingCreator.cascade.negotiationStrategy.text'] })}
                  data-testid="negotiationStrategyForm"
                  formElement={
                    <Controller
                      control={control}
                      defaultValue={cascade.negotiationStrategy || NegotiationStrategies.negotiation}
                      name={`cascades[${index}].negotiationStrategy`}
                      render={({ field }) => (
                        <Select {...field} className={styles.select}>
                          {Object.values(NegotiationStrategies).map((strategy) => (
                            <Select.Option key={strategy} value={strategy}>
                              {formatMessage({ id: NegotiationStrategiesMessages[strategy] })}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                  }
                  note={formatMessage({
                    id:
                      AppMessages[
                        NegotiationStrategiesDescription[
                          negotiationStrategy as keyof typeof NegotiationStrategiesDescription
                        ]
                      ],
                  })}
                />

                <CreatorFormElement
                  title={formatMessage({ id: AppMessages['prospectingCreator.cascade.rankingStrategy.title'] })}
                  text={formatMessage({ id: AppMessages['prospectingCreator.cascade.rankingStrategy.text'] })}
                  data-testid="rankingStrategyForm"
                  formElement={
                    <Controller
                      control={control}
                      defaultValue={cascade.rankingStrategy || RankingStrategies.firstOneWin}
                      name={`cascades[${index}].rankingStrategy`}
                      render={({ field }) => (
                        <Select className={styles.select} {...field}>
                          {Object.values(RankingStrategies).map((strategy) => (
                            <Select.Option key={strategy} value={strategy}>
                              {formatMessage({ id: RankingStrategiesMessages[strategy] })}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                  }
                  note={formatMessage({
                    id:
                      AppMessages[
                        RankingStrategiesDescription[rankingStrategy as keyof typeof RankingStrategiesDescription]
                      ],
                  })}
                />

                {isLastCasdade ? (
                  <div className={styles.displayNone} data-testid="timeDelayHiddenForm">
                    <Controller
                      control={control}
                      name={`cascades[${index}].lifespan`}
                      render={({ field }) => <Input {...field} type="hidden" />}
                    />
                    <Controller
                      control={control}
                      name={`cascades[${index}].immortal`}
                      defaultValue={false}
                      render={({ field }) => <Input {...field} type="hidden" />}
                    />
                  </div>
                ) : (
                  <CreatorFormElement
                    title={formatMessage({ id: AppMessages['prospectingCreator.cascade.timeDelay.title'] })}
                    text={formatMessage({ id: AppMessages['prospectingCreator.cascade.timeDelay.text'] })}
                    errorMessage={errors?.['cascades']?.[index]?.['lifespan']?.['message']}
                    data-testid="timeDelayForm"
                    formElement={
                      <Space>
                        <Controller
                          control={control}
                          name={`cascades[${index}].lifespan`}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={formatMessage({
                                id: AppMessages['prospectingCreator.cascade.timeDelay.placeholder'],
                              })}
                              className={clsx({
                                [styles.error]: errors?.['cascades']?.[index]?.['lifespan']?.['message'],
                              })}
                            />
                          )}
                        />
                        <Typography.Text>
                          {formatMessage({ id: AppMessages['prospectingCreator.cascade.timeDelay.note'] })}
                        </Typography.Text>
                      </Space>
                    }
                    note={
                      <Controller
                        control={control}
                        name={`cascades[${index}].immortal`}
                        render={({ field }) => (
                          <Checkbox checked={field.value} onChange={(e) => field.onChange(e.target.checked)}>
                            {formatMessage({ id: AppMessages['prospectingCreator.cascade.allowRespond.checkbox'] })}
                          </Checkbox>
                        )}
                      />
                    }
                  />
                )}
              </Space>
            </div>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};
