import { useEffect, useState } from 'react';

import { assignVendorToJob, reassignVendorToJob } from 'api/jobProspecting/jobProspecting';
import { VendorOffer } from 'api/jobProspecting/jobProspecting.types';
import { VendorOfferStatus, QueryKey } from 'core/global.enum';
import { useMutation } from 'hooks/useMutation/useMutation';

import { AssignVendor } from './AssignVendor';
import { AssignVendorContainerProps, AssignVendorType } from './AssignVendor.types';

export const AssignVendorContainer = ({
  vendorOffer,
  assignedVendorId,
  jobNumber,
  prospecting,
  status,
}: AssignVendorContainerProps) => {
  const [selectedVendorOffer, setSelectedVendorOffer] = useState<VendorOffer | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTMSReponseError, setIsTMSResponseError] = useState(false);

  const { mutateAsync: onAssignVendorToJob } = useMutation(QueryKey.ASSIGN_VENDOR, assignVendorToJob);

  const { mutateAsync: onReassignVendorToJob } = useMutation(QueryKey.REASSIGN_VENDOR, reassignVendorToJob);

  const handleAssigning = (vendorId: string, type: AssignVendorType) => {
    vendorOffer.status = VendorOfferStatus.awaitingTMSResponse;

    const body = {
      prospectingId: prospecting.id,
      vendorId,
    };

    if (type === 'reassign') {
      onReassignVendorToJob(body);
    } else {
      onAssignVendorToJob(body);
    }
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
    setSelectedVendorOffer(vendorOffer);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedVendorOffer(undefined);
  };

  useEffect(() => {
    let awaitingTMSTimeout!: NodeJS.Timeout;

    if (status === VendorOfferStatus.awaitingTMSResponse) {
      const showTMSResponseError = () => setIsTMSResponseError(true);
      awaitingTMSTimeout = setTimeout(showTMSResponseError, 10000);
    } else {
      clearTimeout(awaitingTMSTimeout);
      setIsTMSResponseError(false);
    }

    return () => {
      clearTimeout(awaitingTMSTimeout);
    };
  }, [status]);

  return (
    <AssignVendor
      vendorOffer={vendorOffer}
      assignedVendorId={assignedVendorId}
      jobNumber={jobNumber}
      handleAssigning={handleAssigning}
      assignVendorStatus={status}
      isModalOpen={isModalOpen}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      selectedVendorOffer={selectedVendorOffer}
      isTMSReponseError={isTMSReponseError}
    />
  );
};
