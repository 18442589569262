import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth/useAuth';
import { AppRoute } from 'routing/AppRoute.enum';

export const useCustomHistory = () => {
  const history = useHistory();
  const { workspaceId } = useAuth();

  const push = ({ route, id }: { route: AppRoute; id?: string }) =>
    history.push(`/${workspaceId}${route}/${id ? id : ''}`);

  return { ...history, push };
};
